<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 168.1 100.9" style="enable-background:new 0 0 168.1 100.9;" xml:space="preserve">
<g id="Capa_2_1_">
	<g id="BOTONERA">
		<g id="TURBINACION">
			<path id="flechas" class="icon-flecha" d="M96.6,57.8L96.6,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0
				c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4L98.7,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0
				L96.6,57.8z M91,57.8L91,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0
				c0.4,0.4,0.4,1,0,1.4L93.2,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L91,57.8L91,57.8z
				 M85.6,57.8L85.6,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4
				L87.7,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L85.6,57.8z M80,57.8L80,57.8
				c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4L82.2,57l2.3,2.3
				c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L80,57.8L80,57.8z M74.6,57.8L74.6,57.8c-0.4-0.4-0.4-1,0-1.4
				c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4L76.7,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0
				c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L74.6,57.8z M69,57.8L69,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0
				c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4L71.2,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0
				L69,57.8L69,57.8z M63.6,57.8L63.6,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0
				c0.4,0.4,0.4,1,0,1.4L65.7,57l2.3,2.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L63.6,57.8z M58,57.8
				L58,57.8c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l3-3l0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l0,0c0.4,0.4,0.4,1,0,1.4L60.2,57l2.3,2.3
				c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L58,57.8L58,57.8z"/>
			<path id="pila" class="icon-btn" d="M167.1,24.8c0-3-2.5-5.4-5.5-5.4h-33.1c-3,0-5.5,2.4-5.5,5.4c0,0,0,0,0,0v61.6
				c0,3,2.5,5.4,5.5,5.4c0,0,0,0,0,0h33.1c3,0,5.4-2.4,5.5-5.4L167.1,24.8z M155,11.3h-19.9v8H155V11.3z"/>
			<path id="pila-b1" class="icon-btn" d="M160,84.1h-29.9v-8.5H160V84.1z"/>
			<path id="pila-b2" class="icon-btn" d="M160,75.6h-29.9v-8.5H160V75.6z"/>
			<path id="pila-ray" class="icon-btn" d="M139.2,29.1h9.3l-2.2,8.2h8.9l-12.7,21.4V45.4h-7.6L139.2,29.1z"/>
			<path class="icon-btn" d="M1.1,33.7l35.1-21.3 M1.1,99.9l17.6-21.3h17.6 M1.1,22.3L36.2,1 M12.6,15.9v10.6 M23.8,9.1v10.6 M9.7,28.7
				v7.5L1,99.9 M18.2,3.6v8.2 M26.7,18.4v7.5l9.6,52.8 M26.7,25.8l-17,10.3 M9.7,36.2l19.9,5.7L4.9,74.7l13.8,4L32.5,58L7.1,55.5
				l19.6-29.7"/>
		</g>
	</g>
</g>
</svg>
</template>

<script>
    export default {
        name: "btn-proceso-turbinacion"
    }
</script>

<style scoped>
	.icon-btn{fill:none;stroke:#94aab4;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
	.icon-flecha{fill:#50d2d2;}
</style>