<template>
    <div id="bombeo-process" class="bombeo-turbinacion">
        <h1>{{ $t('bombeoProcess') }}</h1>
        <h2>{{ $t('bombeoTurbunacionSubtitle') }}</h2>
        <div class="center-images">
            <div class="left-center-column">
                <div class="box box-blue">
                    <span>{{ $t('curvaDemanda') }}</span>
                    <div class="image-content">
                        <BombeoGraphic />
                    </div>
                </div>

                <div class="box box-mini box-border">
                    <div class="image-content">
                        <BtnProcesoBombeo />
                    </div>
                    <span>{{ $t('bombeoProcess') }}</span>
                </div>

                <div class="box box-mini box-blue" @click="$emit('button-selected', 'turbinacion')">
                    <img src="./assets/btn-proceso-T.svg" alt="">
                    <span>{{ $t('turbinacionProcess') }}</span>
                </div>
            </div>

            <div class="right-center-column">
                <Infographie :key="keySvg"/>
            </div>
        </div>
        <div class="text">
            <p>{{ $t('bombeoProcessText') }}</p>
        </div>
    </div>
</template>

<script>

    import Infographie from './components/Infographe'
    import BombeoGraphic from './components/BombeoGraphic'
    import BtnProcesoBombeo from './components/BtnProcesoBombeo'

    export default {
        name: "bombeo-procress",
        components: {
            Infographie,
            BombeoGraphic,
            BtnProcesoBombeo
        },
        data() {
            return {
                keySvg: 0
            }
        },
        created() {
            let intervalId = window.setInterval(() => {
                this.keySvg++;
            }, 10000);
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_turbinacionBombeo.scss";

    .eolic-palas {
        animation: rotateCurve 4s infinite linear;
        transform-origin: 55% 59% !important;
        transform-box: fill-box;
    }

    #curva_bombeo {
        #bolas1 circle {
            animation: lightInOut 2s infinite ease-in-out;
        }
    }

    #bombeo {
        #pila-r1 {
            animation: opacity 2s infinite;
            animation-delay: 0s;
        }
        #pila-r2 {
            animation: opacity 2s infinite;
            animation-delay: 0.2s;
        }
        #pila-r3 {
            animation: opacity 2s infinite;
            animation-delay: 0.4s;
        }
        #pila-r4 {
            animation: opacity 2s infinite;
            animation-delay: 0.6s;
        }
        #pila-r5 {
            animation: opacity 2s infinite;
            animation-delay: 0.8s;
        }
        #pila-r6 {
            animation: opacity 2s infinite;
            animation-delay: 1s;
        }
        #pila-r7 {
            animation: opacity 2s infinite;
            animation-delay: 1.2s;
        }
    }

    @keyframes rotateCurve {
        from {
            transform: rotateZ(0);
        }
        to {
            transform: rotateZ(360deg);
        }
    }

    @keyframes lightInOut {
        0% {
            opacity: 0.4;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.6;
        }
    }
</style>