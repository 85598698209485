import Vue from 'vue'
import App from './App.vue'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

import { languages } from './translations/index.js';
import { defaultLocale } from './translations/index.js';

const messages = Object.assign(languages);

let i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'es',
    messages
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    render: function (h) {
        return h(App)
    },
}).$mount('#app')
