<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 181.1 57.7" style="enable-background:new 0 0 181.1 57.7;" xml:space="preserve">
        <g id="Capa_2_1_">
            <g id="BOTONERA_1_copia_2">
                <g id="bombeo">
                    <g id="iconos">
                        <path id="eolica" class="icon-btn" d="M18.5,20.1l-1.2-4.7L25.1,1l2.2,0.7l-5,15.9L18.5,20.1z M1,29.3l1.4,1.9l14.2-6.4l1.9-4.7
                            l-5,0.6L1,29.3z M18.5,20.1l0.3,4.5l6.2,7.5l1.6-1l-4.1-8.6L18.5,20.1z M18.1,18.5c0,0-2.2-1.6-4.8-3.2
                            c-2.9-1.8-4.9,2.3-2.5,3.8c0.9,0.6,1.8,1.1,2.8,1.6 M13.9,26.1l-1.2,29.6c0.7,0.6,1.5,0.9,2.4,1c0.8,0,1.6-0.4,2.2-1L17,23.9
                             M32.2,25c2.2-4.3,2-9.5-0.4-13.7 M28.5,23.6c1.7-3.2,1.8-7,0.1-10.2 M6.8,8.3c-3.5,3.3-5.1,8.2-4.3,13 M9.8,10.8
                            c-2.7,2.4-4,6-3.6,9.6"/>
                        <path id="pila" class="icon-btn" d="M180.1,14.8c0-1.7-1.4-3.1-3.1-3.2h-19.3c-1.7,0-3.2,1.4-3.2,3.2v35.8c0,1.7,1.4,3.2,3.2,3.2
                            c0,0,0,0,0,0H177c1.7,0,3.2-1.4,3.2-3.1c0,0,0,0,0,0L180.1,14.8z M173.1,7h-11.6v4.7h11.6V7z"/>
                        <path id="pila-r1" class="icon-btn" d="M176,50.1h-17.4v-5H176V50.1z"/>
                        <path id="pila-r2" class="icon-btn" d="M176,45.1h-17.4v-4.9H176V45.1z"/>
                        <path id="pila-r3" class="icon-btn" d="M176,40.1h-17.4v-4.9H176V40.1z"/>
                        <path id="pila-r4" class="icon-btn" d="M176,35.1h-17.4v-4.8H176V35.1z"/>
                        <path id="pila-r5" class="icon-btn" d="M176,30.1h-17.4v-4.8H176V30.1z"/>
                        <path id="pila-r6" class="icon-btn" d="M176,25.2h-17.4v-4.8H176V25.2z"/>
                        <path id="pila-r7" class="icon-btn" d="M176,20.4h-17.4v-4.8H176V20.4z"/>
                    </g>
                </g>
                <g id="flecha-b">
                    <path id="f1" class="icon-flecha" d="M55.3,32.7L55.3,32.7c0.8,0.8,0.8,2.2,0,3l-6.4,6.4l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l5-4.9l-5-4.9c-0.8-0.9-0.6-2.3,0.3-3.1c0.8-0.7,2-0.7,2.8,0L55.3,32.7z"/>
                    <path id="f2" class="icon-flecha" d="M67.3,32.7L67.3,32.7c0.8,0.9,0.7,2.2-0.1,3l-6.4,6.4l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l4.9-4.9l-4.9-4.9c-0.9-0.8-1-2.2-0.2-3s2.2-1,3-0.2c0.1,0.1,0.1,0.1,0.2,0.2L67.3,32.7L67.3,32.7z"/>
                    <path id="f3" class="icon-flecha" d="M78.9,32.7L78.9,32.7c0.8,0.8,0.8,2.2,0,3l-6.4,6.5l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l5-4.9l-5-4.9c-0.8-0.9-0.7-2.3,0.2-3.1c0.9-0.8,2.2-0.8,3,0L78.9,32.7z"/>
                    <path id="f4" class="icon-flecha" d="M90.9,32.7L90.9,32.7c0.8,0.9,0.7,2.2-0.1,3l-6.4,6.5l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l4.9-4.9l-4.9-4.9c-0.9-0.8-1-2.2-0.2-3.1c0.8-0.9,2.2-1,3-0.2c0.1,0.1,0.1,0.1,0.2,0.2L90.9,32.7
                        L90.9,32.7z"/>
                    <path id="f5" class="icon-flecha" d="M102.6,32.7L102.6,32.7c0.8,0.8,0.8,2.2,0,3l-6.4,6.4l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l5-4.9l-5-4.9c-0.8-0.9-0.7-2.3,0.2-3.1c0.9-0.8,2.2-0.8,3,0L102.6,32.7z"/>
                    <path id="f6" class="icon-flecha" d="M114.6,32.7L114.6,32.7c0.8,0.9,0.7,2.2-0.1,3l-6.5,6.5l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l4.9-4.9l-4.9-5c-0.7-0.9-0.5-2.3,0.4-3c0.8-0.6,1.8-0.6,2.6,0l6.4,6.4H114.6z"/>
                    <path id="f7" class="icon-flecha" d="M126.2,32.7L126.2,32.7c0.8,0.8,0.8,2.2,0,3l-6.4,6.4l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l5-4.9l-5-4.9c-0.8-0.9-0.7-2.3,0.2-3.1c0.9-0.8,2.2-0.8,3,0L126.2,32.7z"/>
                    <path id="f8" class="icon-flecha" d="M138.2,32.7L138.2,32.7c0.8,0.9,0.7,2.2-0.1,3l-6.4,6.4l0,0c-0.8,0.8-2.2,0.8-3,0l0,0
                        c-0.8-0.8-0.8-2.2,0-3l4.9-4.9l-4.9-4.9c-0.7-0.9-0.5-2.3,0.4-3c0.8-0.6,1.8-0.6,2.6,0l6.4,6.4H138.2z"/>
                </g>
            </g>
        </g>
        </svg>

</template>

<script>
    export default {
        name: "btn-bombeo"
    }
</script>

<style scoped>
    .icon-btn{fill:none;stroke:#94aab4;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
    .icon-flecha{fill:#50d2d2;}
</style>