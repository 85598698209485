<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 177.4 61.1" style="enable-background:new 0 0 177.4 61.1;" xml:space="preserve">
        <g id="Capa_2_1_">
            <g id="BOTONERA_1_copia_2">
                <g id="turbinacion">
                    <g id="iconos">
                        <path id="torre" class="icon-btn" d="M1.1,20.5l21-12.7 M1.1,60.1l10.5-12.7H22 M1,13.7L22,1 M8,9.9v6.3 M14.6,5.9v6.4 M6.2,17.5V22
                            L1,60.1 M11.3,2.6v4.9 M16.4,11.4v4.5L22,47.4 M16.3,15.8L6.2,22 M6.2,22l11.9,3.4L3.3,45l8.3,2.4L19.8,35L4.6,33.6l11.7-17.7"
                            />
                        <path id="pila" class="icon-btn" d="M150.1,51.4c0,1.8,1.5,3.3,3.3,3.2h19.8c1.8,0,3.3-1.5,3.3-3.2V14.6c0-1.8-1.5-3.3-3.2-3.3
                            c0,0,0,0,0,0h-19.8c-1.8,0-3.3,1.4-3.3,3.2c0,0,0,0,0,0V51.4z M157.3,11.4h11.9V6.5h-11.9V11.4z"/>
                        <path id="pila-ray" class="icon-btn" d="M169.3,26.9h-4.6v8l-7.6-12.8h5.3l-1.3-4.9h5.6L169.3,26.9z"/>
                        <path id="pila-r8" class="icon-btn" d="M154.3,45h17.9v5.1h-17.9V45z"/>
                        <path id="pila-r9" class="icon-btn" d="M154.3,39.9h17.9V45h-17.9V39.9z"/>
                    </g>
                </g>
                <g id="flecha-t">
                    <path id="f1" class="icon-flecha" d="M123.9,35.3L123.9,35.3c-0.8-0.8-0.8-2.2,0-3l6.5-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-5,4.9l5,4.9c0.8,0.9,0.7,2.3-0.2,3.1c-0.8,0.7-2.1,0.7-2.9,0L123.9,35.3z"/>
                    <path id="f2" class="icon-flecha" d="M111.9,35.3L111.9,35.3c-0.8-0.9-0.7-2.2,0.1-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-4.9,4.9l4.9,4.9c0.7,0.9,0.5,2.3-0.4,3c-0.8,0.6-1.8,0.6-2.6,0l-6.4-6.4H111.9z"/>
                    <path id="f3" class="icon-flecha" d="M100.2,35.3L100.2,35.3c-0.8-0.8-0.8-2.2,0-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-5,4.9l5,4.9c0.9,0.8,1,2.2,0.2,3.1c-0.8,0.9-2.2,1-3.1,0.2c-0.1-0.1-0.1-0.1-0.2-0.2L100.2,35.3z"/>
                    <path id="f4" class="icon-flecha" d="M88.3,35.3L88.3,35.3c-0.8-0.9-0.7-2.2,0.1-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-4.9,4.9l4.9,4.9c0.7,0.9,0.5,2.3-0.4,3c-0.8,0.6-1.8,0.6-2.6,0L88.3,35.3L88.3,35.3z"/>
                    <path id="f5" class="icon-flecha" d="M76.6,35.3L76.6,35.3c-0.8-0.8-0.8-2.2,0-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-5,4.9l5,4.9c0.7,1,0.5,2.3-0.5,3.1c-0.8,0.6-1.8,0.6-2.6,0L76.6,35.3z"/>
                    <path id="f6" class="icon-flecha" d="M64.7,35.3L64.7,35.3c-0.8-0.9-0.8-2.2,0.1-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0
                        c0.8,0.8,0.8,2.2,0,3l-4.9,4.9l4.9,4.9c1,0.7,1.2,2.1,0.4,3c-0.7,1-2.1,1.2-3,0.4c-0.2-0.1-0.3-0.3-0.4-0.4L64.7,35.3L64.7,35.3z
                        "/>
                    <path id="f7" class="icon-flecha" d="M53,35.3L53,35.3c-0.8-0.8-0.8-2.2,0-3l6.4-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0c0.8,0.8,0.8,2.2,0,3
                        l-5,4.9l5,4.9c0.8,0.9,0.7,2.3-0.2,3.1c-0.8,0.7-2.1,0.7-2.9,0L53,35.3z"/>
                    <path id="f8" class="icon-flecha" d="M41,35.3L41,35.3c-0.8-0.9-0.7-2.2,0.1-3l6.5-6.4l0,0c0.8-0.8,2.2-0.8,3,0l0,0c0.8,0.8,0.8,2.2,0,3
                        l-4.9,4.9l4.9,4.9c0.7,0.9,0.5,2.3-0.4,3c-0.8,0.6-1.8,0.6-2.6,0L41,35.3L41,35.3z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "btn-turbinacion"
    }
</script>

<style scoped>
    .icon-btn{fill:none;stroke:#94aab4;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
    .icon-flecha{fill:#50d2d2;}
</style>