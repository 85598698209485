<template>
    <div id="turbinacion-process" class="bombeo-turbinacion">
        <h1>{{ $t('turbinacionProcess') }}</h1>
        <h2>{{ $t('bombeoTurbunacionSubtitle') }}</h2>
        <div class="center-images">
            <div class="left-center-column">
                <div class="box box-blue">
                    <span>{{ $t('curvaDemanda') }}</span>
                    <div class="image-content">
                        <TurbinacionGraphic />
                    </div>
                </div>

                <div class="box box-mini box-blue" @click="$emit('button-selected', 'bombeo')">
                    <img src="./assets/btn-proceso-B.svg" alt="">
                    <span>{{ $t('bombeoProcess') }}</span>
                </div>

                <div class="box box-mini box-border">
                    <div class="image-content">
                        <BtnProcesoTurbinacion />
                    </div>
                    <span>{{ $t('turbinacionProcess') }}</span>
                </div>
            </div>

            <div class="right-center-column">
                <Infographie :key="keySvg"/>
            </div>
        </div>
        <div class="text">
            <p>{{ $t('turbinacionProcessText') }}</p>
        </div>
    </div>
</template>

<script>

    import Infographie from './components/Infographe'
    import TurbinacionGraphic from './components/TurbinacionGraphic'
    import BtnProcesoTurbinacion from './components/BtnProcesoTurbinacion'

    export default {
        name: "bombeo-procress",
        components: {
            Infographie,
            TurbinacionGraphic,
            BtnProcesoTurbinacion,
        },
        data() {
            return {
                keySvg: 0
            }
        },
        created() {
            let intervalId = window.setInterval(() => {
                this.keySvg++;
            }, 10000);
        }
    }
</script>

<style lang="scss">
    @import "@/scss/_turbinacionBombeo.scss";

    #TURBINACION {
        #pila-ray {
            animation: lightInOut 2s infinite;
        }
        #pila-b1 {
            opacity: 0;
            animation: opacity 2s infinite  forwards;
            animation-delay: 0.2s;
        }
        #pila-b2 {
            opacity: 0;
            animation: opacity 2s infinite forwards;
            animation-delay: 0s;
        }
    }

    #curva_turbinacion {
        #bolas2 circle {
            animation: lightInOut 2s infinite ease-in-out;
        }
    }

    @keyframes lightInOut {
        0% {
            opacity: 0.4;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.6;
        }
    }

    @-webkit-keyframes opacity {
        from {opacity: 0;}
        to {opacity: 1;}
    }
</style>