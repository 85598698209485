<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.01 62.01">
        <defs>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_5" data-name="Capa 5">
                <path id="icon-reverso" class="icon-color" d="M55.53,41.89H41.48a3.1,3.1,0,1,0,0,6.2h7.45A24.81,24.81,0,0,1,6.2,31,3.1,3.1,0,1,0,0,31,31,31,0,0,0,52.34,53.42v5.49a3.1,3.1,0,0,0,6.2,0V45A3.11,3.11,0,0,0,55.53,41.89ZM40.31,31a9.31,9.31,0,1,0-9.3,9.3A9.31,9.31,0,0,0,40.31,31ZM27.9,31A3.11,3.11,0,1,1,31,34.11,3.11,3.11,0,0,1,27.9,31ZM31,0A31,31,0,0,0,9.67,8.59V3.1a3.1,3.1,0,0,0-6.2,0v14a3.1,3.1,0,0,0,3.1,3.1h14a3.1,3.1,0,0,0,0-6.2H13.08A24.81,24.81,0,0,1,55.81,31,3.1,3.1,0,0,0,62,31,31,31,0,0,0,31,0Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "icon-reservo"
    }
</script>

<style scoped>

</style>