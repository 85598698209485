<template>
    <div id="buttons">
        <h1>{{ $t('introTitle') }}</h1>
        <p>{{ $t('introSubtitle') }}</p>
        <div class="buttons">
            <div class="button" id="central-functioning" @click="buttonSelected('central-functioning')">
                <div class="image-icon">
                    <IconReservo />
                </div>
                <span>{{ $t('buttonOne') }}</span>
            </div>

            <div class="button" id="description-project" @click="buttonSelected('description-project')">
                <div class="image-icon">
                    <IconDescription />
                </div>
                <span>{{ $t('buttonTwo') }}</span>
            </div>

            <div class="button" id="future-steps" @click="buttonSelected('future-steps')">
                <div class="image-icon">
                    <IconSalto />
                </div>
                <span>{{ $t('buttonThree') }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import IconDescription from './assets/IconDescription.vue';
    import IconReservo from './assets/IconReservo.vue';
    import IconSalto from './assets/IconSalto.vue';

    export default {
        name: "buttons",
        components: {
            IconDescription,
            IconReservo,
            IconSalto
        },
        methods: {
            buttonSelected: function (button) {

                document.getElementById(button).classList.add('active');
                setTimeout(() => {
                    this.$emit('button-selected', button)
                }, 3000);
            }
        }
    }
</script>

<style lang="scss">

    #pila-r8 {
        opacity: 0;
        animation: opacity 2s infinite  forwards;
        animation-delay: 0.2s;
    }
    #pila-r9 {
        opacity: 0;
        animation: opacity 2s infinite forwards;
        animation-delay: 0s;
    }

    #buttons {
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            width: 900px;
            text-align: center;
            font-size: 22px;
            line-height: 30px;
            color: var(--color-text);
        }
        .buttons {
            margin-top: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            .button {
                cursor: pointer;
                width: 170px;
                height: 170px;
                border-radius: 25px;
                border: 2px solid var(--color-primary);
                background-color: var(--color-blue-new);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .image-icon {
                    width: 30%;
                    height: auto;
                    margin-bottom: 10px;
                    svg {
                        width: 100%;
                        height: auto;
                        fill: #50d2d2;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                    }
                }
                span {
                    display: block;
                    width: 70%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 15px;
                    line-height: 18px;
                }
                &:nth-child(2) {
                    margin: 0 35px;
                }
                &.active {
                    .image-icon {
                        animation: pop 1.5s infinite;
                        * {
                            fill: var(--color-primary) !important;
                        }
                    }
                }
            }
        }
    }

    @keyframes pop {
        0% { transform: translate(-0%, -0%); }
        50% { transform: translate(-0%, -10%) scale(1, 1); }
        55% { transform: translate(-0%, -10%) rotate(15deg); }
        60% { transform: translate(-0%, -10%) rotate(-15deg); }
        65% { transform: translate(-0%, -10%) rotate(15deg); }
        70% { transform: translate(-0%, -10%) rotate(-15deg); }
        100% { transform: translate(-0%, -0%); }
    }

    @-webkit-keyframes opacity {
        from {opacity: 0;}
        to {opacity: 1;}
    }
</style>