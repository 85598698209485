<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.45 123.64">
        <g id="Capa_2-2" data-name="Capa 2">
            <g id="curva_bombeo" data-name="curva turbinacion">
                <path id="curva" class="linea-curva" d="M1,74.76C14.84,95.88,39.79,98.19,53.89,94.93c21.27-4.24,35.79-22.46,39.94-47.45C98.3,20.58,122.89.86,155.28,22c26.36,17.17,35.56.31,44.83-10.82S224,2.88,226.74,22c4.8,28.14,18.09,43,42.71,55.37" />
                <g id="lineas1" class="linea-y">
                    <line x1="8.88" y1="85.41" x2="8.88" y2="106.41" />
                    <line x1="71.89" y1="85.41" x2="71.89" y2="106.41" />
                    <line x1="165.14" y1="23.1" x2="165.14" y2="106.1" />
                    <line x1="255.93" y1="66.41" x2="255.93" y2="106.41" />
                </g>
                <g id="lineas2" class="linea-w">
                    <line x1="120.15" y1="15.1" x2="120.15" y2="106.1" />
                    <line x1="210.24" y1="6.1" x2="210.24" y2="106.1" />
                </g>
                <g id="bolas1" class="circulo-y">
                    <circle cx="8.88" cy="84.23" r="4.89" />
                    <circle cx="71.89" cy="87.59" r="4.89" />
                    <circle cx="165.14" cy="27.1" r="4.89" />
                    <circle cx="255.8" cy="69.5" r="4.89" />
                </g>
                <g id="bolas2" class="circulo-b">
                    <circle cx="120.15" cy="14.28" r="4.89" />
                    <circle cx="210.13" cy="4.89" r="4.89" />
                </g>
                <g id="num">
                    <path class="num-curva" d="M70.46,119.53a4.92,4.92,0,0,1-.64,2.81,2.67,2.67,0,0,1-3.77.23h0l-.24-.23a4.67,4.67,0,0,1-.68-2.68v-1.42a4.7,4.7,0,0,1,.64-2.74,2.74,2.74,0,0,1,3.87-.18l.17.18a4.62,4.62,0,0,1,.66,2.74Zm-1.07-1.46a3.94,3.94,0,0,0-.38-2,1.35,1.35,0,0,0-1.22-.62,1.3,1.3,0,0,0-1.19.62,3.71,3.71,0,0,0-.39,1.91v1.78a4,4,0,0,0,.4,2,1.3,1.3,0,0,0,1.2.64,1.28,1.28,0,0,0,1.18-.61,3.8,3.8,0,0,0,.4-1.93Z" />
                    <path class="num-curva" d="M76.21,114.7v.91H76a2.83,2.83,0,0,0-2,.75,3,3,0,0,0-.86,2,2.48,2.48,0,0,1,3.51-.08l.08.08a3.38,3.38,0,0,1,0,4.08,2.62,2.62,0,0,1-3.71,0h0l-.16-.18a3.61,3.61,0,0,1-.75-2.39v-.41a5.51,5.51,0,0,1,1-3.56,3.63,3.63,0,0,1,3-1.26Zm-1.41,3.79a1.82,1.82,0,0,0-1.67,1.16v.41a2.63,2.63,0,0,0,.46,1.68,1.4,1.4,0,0,0,1.17.64,1.34,1.34,0,0,0,1.14-.53,2.21,2.21,0,0,0,.41-1.39,2.17,2.17,0,0,0-.42-1.41,1.36,1.36,0,0,0-1.16-.56Z" />
                    <path class="num-curva" d="M8,119.53a5,5,0,0,1-.64,2.81,2.29,2.29,0,0,1-2,.91,2.32,2.32,0,0,1-2-.91,4.76,4.76,0,0,1-.67-2.68v-1.42a4.86,4.86,0,0,1,.64-2.74,2.32,2.32,0,0,1,2-.91,2.32,2.32,0,0,1,2,.91A4.62,4.62,0,0,1,8,118.24Zm-1.07-1.46a3.94,3.94,0,0,0-.39-2,1.32,1.32,0,0,0-1.21-.58,1.33,1.33,0,0,0-1.2.62A3.82,3.82,0,0,0,3.72,118v1.74a4,4,0,0,0,.37,2,1.28,1.28,0,0,0,1.19.64,1.29,1.29,0,0,0,1.19-.61,3.9,3.9,0,0,0,.4-1.93Z" />
                    <path class="num-curva" d="M14.76,119.53a4.92,4.92,0,0,1-.64,2.81,2.29,2.29,0,0,1-2,.91,2.32,2.32,0,0,1-2-.91,4.58,4.58,0,0,1-.66-2.68v-1.42a4.7,4.7,0,0,1,.64-2.74,2.29,2.29,0,0,1,2-.91,2.32,2.32,0,0,1,2,.91,4.62,4.62,0,0,1,.66,2.74Zm-1.07-1.46a3.94,3.94,0,0,0-.38-2,1.34,1.34,0,0,0-1.18-.61,1.3,1.3,0,0,0-1.19.62,3.71,3.71,0,0,0-.39,1.91v1.77a4,4,0,0,0,.4,2,1.28,1.28,0,0,0,1.19.64,1.29,1.29,0,0,0,1.19-.61,3.9,3.9,0,0,0,.4-1.93Z" />
                    <path class="num-curva" d="M117.3,123.13h-1.08V116l-2.16.8v-1l3.07-1.13h.17Z" />
                    <path class="num-curva" d="M124.13,123.13h-1.08V116l-2.16.8v-1l3.07-1.15h.17Z" />
                    <path class="num-curva" d="M162.65,123.13h-1.07V116l-2.17.8v-1l3.08-1.15h.16Z" />
                    <path class="num-curva" d="M166.41,118.9l.43-4.2h4.29v1h-3.41l-.26,2.3a2.79,2.79,0,0,1,1.41-.36,2.32,2.32,0,0,1,1.82.75,3,3,0,0,1,.68,2.07,2.9,2.9,0,0,1-.7,2.05,2.57,2.57,0,0,1-2,.75,2.65,2.65,0,0,1-1.83-.62,2.51,2.51,0,0,1-.8-1.72h1a1.7,1.7,0,0,0,.52,1.1,1.53,1.53,0,0,0,1.1.36,1.38,1.38,0,0,0,1.17-.51,2.08,2.08,0,0,0,.43-1.4,1.93,1.93,0,0,0-.46-1.36,1.51,1.51,0,0,0-1.23-.51,1.67,1.67,0,0,0-1.1.31l-.28.22Z" />
                    <path class="num-curva" d="M208,123.13h-1.07V116l-2.16.8v-1l3.07-1.15H208Z" />
                    <path class="num-curva" d="M215.37,119.43a2.76,2.76,0,0,1-.8.64,2.05,2.05,0,0,1-1,.24,2.19,2.19,0,0,1-2.11-1.37,3.54,3.54,0,0,1-.3-1.44,3.81,3.81,0,0,1,.32-1.53,2.53,2.53,0,0,1,.92-1,2.64,2.64,0,0,1,1.37-.35,2.38,2.38,0,0,1,2,.91,4.22,4.22,0,0,1,.72,2.56v.31a5.46,5.46,0,0,1-1,3.59,3.71,3.71,0,0,1-2.94,1.16h-.21v-.91h.23a2.92,2.92,0,0,0,2-.68A3,3,0,0,0,215.37,119.43Zm-1.65,0a1.64,1.64,0,0,0,1-.33,1.85,1.85,0,0,0,.67-.82v-.42a2.9,2.9,0,0,0-.46-1.72,1.37,1.37,0,0,0-1.88-.48h0a1.44,1.44,0,0,0-.39.35,2.19,2.19,0,0,0-.43,1.42,2.41,2.41,0,0,0,.41,1.43,1.35,1.35,0,0,0,1.1.56Z" />
                    <path class="num-curva" d="M254.93,123.13H249.4v-.76l2.92-3.25a7.58,7.58,0,0,0,.91-1.2,1.94,1.94,0,0,0,.25-.91,1.57,1.57,0,0,0-.4-1.09,1.39,1.39,0,0,0-1.07-.42,1.67,1.67,0,0,0-1.24.46,1.74,1.74,0,0,0-.44,1.27h-1.08a2.56,2.56,0,0,1,.75-1.89,2.82,2.82,0,0,1,2-.72,2.68,2.68,0,0,1,1.82.61,2.1,2.1,0,0,1,.69,1.65,4.87,4.87,0,0,1-1.59,3l-2.26,2.45h4.24Z" />
                    <path class="num-curva" d="M261.71,123.13h-5.53v-.76l2.92-3.25a6.65,6.65,0,0,0,.91-1.2,1.94,1.94,0,0,0,.25-.91,1.57,1.57,0,0,0-.4-1.09,1.38,1.38,0,0,0-1.05-.42,1.67,1.67,0,0,0-1.24.46,1.74,1.74,0,0,0-.44,1.27h-1.08a2.56,2.56,0,0,1,.75-1.89,2.75,2.75,0,0,1,2-.75,2.7,2.7,0,0,1,1.83.61,2.08,2.08,0,0,1,.68,1.64,4.87,4.87,0,0,1-1.59,3l-2.25,2.45h4.23Z" />
                </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "bombeo-graphic"
    }
</script>

<style scoped>
    .forma-curva{fill:#141f35;}
    .btn-txt{font-size:19.12px;fill:#fff;font-family:DINNextLTPro-Bold, DIN Next LT Pro;font-weight:700;}
    .linea-curva{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke:#fff;stroke-width:2px;}
    .linea-w{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke:#fff;stroke-width:1.5px;}
    .linea-y{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke:#50d2d2;stroke-width:1.5px;}
    .num-curva{fill:#94a8b3;stroke:#94a8b3;stroke-miterlimit:10;stroke-width:0.75px;}
    .circulo-b{fill:#00a2a4;}
    .circulo-y{fill:#50d2d2;}
</style>