<template>
    <svg id="chira-svg-complete" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 965.01 499.66">
        <defs>

            <linearGradient id="left-to-right-1">
                <stop offset="0" stop-color="#00a7d4">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1" begin="0s"/>
                </stop>
                <stop offset="0" stop-color="#fff">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1"  begin="0s"/>
                </stop>
            </linearGradient>

            <linearGradient id="left-to-right-2">
                <stop offset="0" stop-color="#00a7d4">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1"  begin="3s"/>
                </stop>
                <stop offset="0" stop-color="#fff">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1"  begin="3s"/>
                </stop>
            </linearGradient>

            <linearGradient id="left-to-right-3">
                <stop offset="0" stop-color="#00a7d4">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1" begin="6s"/>
                </stop>
                <stop offset="0" stop-color="#fff">
                    <animate dur="3s" attributeName="offset" fill="freeze" from="0" to="1"begin="6s"/>
                </stop>
            </linearGradient>

            <clipPath id="clip-path">
                <rect class="cls-1b" x="268.49" y="26.62" width="493.46" height="447.09"/>
            </clipPath>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
            <g id="bloque_central" data-name="bloque central">
                <rect id="C-rectangulo" class="linea-rectangulo" x="217.73" y="1.5" width="559.55" height="496.66"
                      rx="18.93" ry="18.93"/>
                <polygon id="Szona-c" class="Sombra"
                         points="274.8 344.2 392.46 399.12 392.55 471.94 274.9 417.35 274.8 344.2"/>
                <polygon id="Szona-b" class="Sombra"
                         points="461.88 356.16 393.09 398.64 393.65 469.38 461.88 426.4 461.88 356.16"/>
                <path id="Szona-g" class="Sombra"
                      d="M725,98.26s-21.15,8.31-29.65,10.3-39.1,5.09-39.1,5.09L590.58,217.33,465,297l-4.27,131.52,244.9-155,.4-121.3Z"/>
                <path id="Szona-a" class="Sombra"
                      d="M636.29,143.62a162.3,162.3,0,0,0-42.12-21.12c-9.78-3.1-38.71-9.85-38.71-9.85l-43.23,69s30.24,6.57,39.54,10.11c17.66,6.73,36,24.21,36,24.21l.14,3.07,2.42-1.53.36-1.65Z"/>
                <path id="Szona-m" class="Sombra"
                      d="M590.33,217.51c-11.55-11.54-26.05-21.92-41.05-26.87-14.39-5.85-37.56-9.6-37.56-9.6L386.49,260.39l77,36.28Z"/>
                <g id="chira">
                    <g id="chira-total">
                        <g id="embalse1">
                            <path class="agua"
                                  d="M707.35,103.05a6.36,6.36,0,0,0-2.7-2,6.16,6.16,0,0,0-3.13.18,5,5,0,0,1-2.14.23,5,5,0,0,1-1.63-1.16A6.53,6.53,0,0,0,695,98.62a6.18,6.18,0,0,0-3.13.37,5.19,5.19,0,0,1-2.1.35A5,5,0,0,1,688,98.27a6.43,6.43,0,0,0-2.85-1.58,6.16,6.16,0,0,0-3.11.52,2.92,2.92,0,0,1-3.84-.55,6.52,6.52,0,0,0-2.9-1.45,6.36,6.36,0,0,0-3.09.66,2.88,2.88,0,0,1-3.82-.4,4.86,4.86,0,0,0-6-.56,2.9,2.9,0,0,1-3.87-.26,6.43,6.43,0,0,0-3-1.21,6.34,6.34,0,0,0-3,.88,2.92,2.92,0,0,1-3.88-.12,6.37,6.37,0,0,0-3-1.11h0a6.52,6.52,0,0,0-3,1,4.75,4.75,0,0,1-2,.75,7.77,7.77,0,0,1-2.74-.79,9.5,9.5,0,0,0-3.54-.94h-.08a9.19,9.19,0,0,0-3.64,1.08A7.64,7.64,0,0,1,628,95a7.9,7.9,0,0,1-2.78-.69,9.11,9.11,0,0,0-3.64-.81A9.26,9.26,0,0,0,618,94.72a7.59,7.59,0,0,1-2.61,1,7.76,7.76,0,0,1-2.81-.58,7,7,0,0,0-7.24.71,7.84,7.84,0,0,1-2.55,1.06,8,8,0,0,1-2.84-.45,9,9,0,0,0-3.68-.49,9.3,9.3,0,0,0-3.55,1.57,7.74,7.74,0,0,1-2.45,1.19,8.07,8.07,0,0,1-2.89-.28,8.86,8.86,0,0,0-3.69-.26,9.33,9.33,0,0,0-3.49,1.85,7.76,7.76,0,0,1-2.31,1.34,8.42,8.42,0,0,1-2.92,0,8.76,8.76,0,0,0-3.71.1,9.38,9.38,0,0,0-3.39,2.34,8.13,8.13,0,0,1-2,1.55,9.29,9.29,0,0,1-2.91.48,8.12,8.12,0,0,0-3.69.82c-1.52.91-3.45,2.28-3,3.69.18.55,0,1.14.18,1.78l-.23.94-.16.54s31,5.84,42.07,10.14c9.4,3.67,38.67,19.89,38.67,19.89l18.4-29.21s18.89-1.13,25.47-2.13,27.58-5.88,27.58-5.88l.71-2.11A5.08,5.08,0,0,1,707.35,103.05Z"/>
                        </g>
                        <rect class="linea-blanca" x="391.43" y="399.85" width="2.22" height="71.85"/>
                        <path class="linea-blanca"
                              d="M588.86,218.72c-13.12-16.22-33.23-25.14-47.78-29.76a150.49,150.49,0,0,0-28.85-6.22H512l-.18-.09L472,163.38l1-2,39.68,19.17a153.59,153.59,0,0,1,29.07,6.28,128.51,128.51,0,0,1,25.62,11.11,83.47,83.47,0,0,1,23.26,19.39Z"/>
                        <path class="linea-blanca"
                              d="M636.06,144.48c-17.19-12.94-38.34-20.4-53.06-24.39a199,199,0,0,0-28.1-5.6l.22-2.21A201.43,201.43,0,0,1,583.58,118a196.5,196.5,0,0,1,26.67,9.25,134.71,134.71,0,0,1,27.15,15.51Z"/>
                        <g id="embalse2">
                            <path class="agua"
                                  d="M458.54,353.16a13.75,13.75,0,0,0-9.14-4.3,9.86,9.86,0,0,1-4.19-1.07,10.27,10.27,0,0,1-3.51-2.54,12.11,12.11,0,0,0-4.15-3,12.42,12.42,0,0,0-5-1.28,10.07,10.07,0,0,1-4.2-1.06,10.63,10.63,0,0,1-3.5-2.55,12.24,12.24,0,0,0-4.16-3,12.42,12.42,0,0,0-5-1.28,11.51,11.51,0,0,1-7.7-3.61,13.75,13.75,0,0,0-9.14-4.3,9.86,9.86,0,0,1-4.19-1.07,10.27,10.27,0,0,1-3.51-2.54,12,12,0,0,0-4.16-3,12.42,12.42,0,0,0-5-1.28,11.44,11.44,0,0,1-7.7-3.62,13.78,13.78,0,0,0-9.14-4.29,11.46,11.46,0,0,1-7.7-3.62,12.38,12.38,0,0,0-4.16-3c-4.29-2-4.46-2.06-9.16-2.36H344c-3-.2-3.37,0-5.65,1.4L338,302a6.11,6.11,0,0,0-2.4,2.75,3.8,3.8,0,0,1-1.59,1.86,3.89,3.89,0,0,1-2.37.63,6.35,6.35,0,0,0-5.92,3.72,3.8,3.8,0,0,1-1.59,1.86,3.89,3.89,0,0,1-2.37.63,6.43,6.43,0,0,0-5.92,3.75,4.12,4.12,0,0,1-4,2.49,6.35,6.35,0,0,0-5.92,3.72,4.12,4.12,0,0,1-4,2.49,6.43,6.43,0,0,0-5.92,3.75,4.12,4.12,0,0,1-4,2.49,6.35,6.35,0,0,0-5.92,3.72,4.15,4.15,0,0,1-4,2.49,6.35,6.35,0,0,0-5.92,3.72,3.87,3.87,0,0,1-1.6,1.86l1.18,1.88c1.5-.94,116.08,55,116.48,54.19l68.63-42.14,1-2A10.14,10.14,0,0,1,458.54,353.16Z"/>
                        </g>
                        <g id="perfil">
                            <rect class="linea-blanca" x="470.92" y="158.18" width="14.44" height="2.22"
                                  transform="translate(85.59 475.35) rotate(-57.26)"/>
                            <path class="linea-blanca"
                                  d="M723.13,78.68c-4.54-3-11-5.64-19.2-7.91-16.74-4.65-39-7.2-62.62-7.2-20,0-38.91,1.81-54.64,5.23a112.63,112.63,0,0,0-19.45,5.87c-5.05,2.12-8.89,4.44-11.44,6.92L519,64.29c10-11.13,34.66-20.64,65.06-25l-.32-2.2c-15.93,2.29-30.62,6-42.49,10.82C529.63,52.59,521,58.15,516.33,64l-.25.7V93.49L508,106.24l1.87,1.19,7.7-12.1,36.78,17.16-42.1,67.19-125.5,79.23-41.47-19.49-1.07.07L322.34,253.4l-.38.41-47.89,88.85v.06h0l-.1,73.44.64,1,118.2,55.44,1.07-.08,67.67-43.44h0l244.88-154.8.51-.94V152.47l21.88-58.14a9.14,9.14,0,0,0,1.74-5.21C730.56,85.42,728.06,81.91,723.13,78.68ZM518.3,93.23V66.43L554.85,83.6v26.69ZM459.83,427.54l-66.66,42.79-117-54.87.1-70.48,116.52,54.73,1.07-.06,67.54-42.53Zm1.62-73.08-68.27,43L276.58,342.7l47.19-87.56,19.93-12.67V299.1l2.23.13V242.17l116.8,54.89ZM704.8,151.88l-.08.39V272.73L462.08,426.11,465,297l126-79.18.35-.35L656.53,114.3c20.2-1,38.7-3.9,52.13-8.26,7.16-2.31,12.63-4.94,16.31-7.78Zm18.36-55c-3.5,2.58-8.61,5-15.18,7.1-13.38,4.34-31.9,7.25-52.13,8.18l-.89.52L589.62,216.07l-125.85,79L389.13,260l124.5-78.61.35-.34,42.92-68.5L556,112h1.11V83.44c4.79-4.86,15.44-9.28,30.08-12.47,15.57-3.39,34.29-5.18,54.16-5.18,23.44,0,45.47,2.53,62,7.12,15.89,4.4,25,10.31,25,16.21,0,2.59-1.75,5.18-5.19,7.71Z"/>
                        </g>
                        <g id="linea-discontinua-azul1">
                            <path class="color-blue"
                                  d="M345.19,311.35h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Zm0,8.78h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Zm0,8.77h-2.12v-3h2.12Z"/>
                        </g>
                        <g id="linea-discontinua-azul2">
                            <path class="color-blue"
                                  d="M336.39,382.57l-1.06-1.84,2.46-1.42,1.07,1.84Zm-7.25,4.19-1.06-1.84,2.46-1.42,1.07,1.83ZM321.89,391l-1.06-1.84,2.46-1.43,1.06,1.84Zm-7.25,4.19-1.06-1.85,2.46-1.42,1.06,1.84Zm-7.25,4.18-1.07-1.84,2.47-1.42,1.06,1.84Zm-7.26,4.19-1.06-1.84,2.46-1.42,1.07,1.84Zm-7.25,4.19-1.06-1.84,2.46-1.42,1.07,1.84Zm-7.25,4.19-1.06-1.84,2.46-1.42,1.06,1.84Zm-7.25,4.19-1.07-1.84,2.47-1.43,1.06,1.84Z"/>
                        </g>
                        <g id="linea-discontinua">
                            <path class="linea-blanca"
                                  d="M465.92,168.56l-1.1-1.81,2.45-1.5,1.1,1.82Zm-7.05,4.29-1.1-1.81,2.45-1.5,1.1,1.82Zm-7.05,4.29-1.1-1.81,2.45-1.5,1.1,1.82Zm-7.05,4.29-1.1-1.82,2.45-1.49,1.1,1.82Z"/>
                        </g>
                    </g>
                    <g id="torrre-centro">
                        <g id="enganche">
                            <path class="linea-blanca"
                                  d="M390.7,182.55h-.45v-5h.45Zm-15.3-7.28H375v-5h.44ZM360.1,168h-.44v-5h.44Z"/>
                        </g>
                        <path id="torre" class="linea-blanca"
                              d="M396.11,244.31l-19-15.64-18.71,3.24-1.29-1.27L364.83,182v-6.62l1.58-1,1.14.53v-7l-9.15-4.36,1-2,14.72,7V160.1h2.22v9.44L392,177l-1,2-8.2-3.9V182l2.09,1,.64,1v6.59l12.36,52.65ZM380,228.18l15,12.32-3.36-14.32ZM361.72,216l-2.14,13.5,15.31-2.64Zm1.63-1.53,14.42,11.89L390,224.2l-14.41-12.72Zm14.87-3.57,12.36,10.92-3.09-13.15ZM364,201.28l-1.75,11.11,11.27-2.7Zm1.31-1.81,10.84,9.58,10.08-2.41L376.1,195.18Zm13-5.16,7.93,8.95-2.6-11.08Zm-11.63-9.76-2,12.81,9.89-3.94Zm3.66.77,6.4,7.22,4.89-1.94Zm-3.28-4,16.26,7.59v-4.26l-2.05-1h0l-5.44-2.58L367.1,177Zm9.68-2.19,3.85,1.8V174l-5.65-2.69h-.86v-.41l-4.3-2v6.94Z"/>
                    </g>
                    <g id="linea-tension-c">
                        <g id="tension">
                            <path class="linea-tension-y" d="M423.78,236.4l1.05,1.07"/>
                            <path class="linea-tension-y-r " d="M427,239.62a348.17,348.17,0,0,0,65.19,51.83"/>
                            <path class="linea-tension-y" d="M493.46,292.24l1.28.77"/>
                            <path class="linea-tension-y" d="M375.2,175.32s16,27.5,47.69,60.05"/>
                            <path class="linea-tension-y-r " d="M415.26,242c19.91,22.34,43.5,44.47,67.9,57.89"/>
                            <path class="linea-tension-y" d="M359.76,168a520.17,520.17,0,0,0,53.72,72.19"/>
                            <path class="linea-tension-y-r " d="M429.73,232.41c17.45,17,41.56,36.35,74,54.89"/>
                            <path class="linea-tension-y" d="M390.25,182.55s8.79,19.88,37.87,48.18"/>
                        </g>
                    </g>
                    <g id="bateria">
                        <path class="color-yellow"
                              d="M524.43,349.58l-.16-.08-44-20.67V311.42l.16-.1L508,294.58l44.21,20.78v17.41ZM481,328.41l43.13,20.27v-16.5h.67v16.43l26.74-16.21V315.78L508,295.34l-27,16.45Z"/>
                        <polygon id="bateria-box-1" class="color-blue bateria-box"
                                 points="525.15 306.85 516.43 312.13 499.97 304.36 508.67 299.07 525.15 306.85"/>
                        <polygon id="bateria-box-2" class="color-blue bateria-box"
                                 points="514.08 313.56 505.36 318.84 488.9 311.07 497.6 305.78 514.08 313.56"/>
                        <polygon id="bateria-box-3" class="color-blue bateria-box"
                                 points="544.2 315.68 535.48 320.96 519.36 313.42 528.06 308.12 544.2 315.68"/>
                        <polygon id="bateria-box-4"  class="color-blue bateria-box"
                                 points="533.13 322.39 524.41 327.67 508.29 320.13 516.99 314.84 533.13 322.39"/>
                        <path class="color-yellow"
                              d="M480.29,311.88V301.43l7.08-3.9v10Zm.66-10.06v8.88l5.75-3.5v-8.54Zm9.52,4.12V295.15l7.08-3.9v10.38Zm.67-10.39v9.2l5.74-3.5v-8.87Zm9.51,4.11V288.88l7.09-3.9v10.37Zm.67-10.39v9.21l5.75-3.5V286.1Z"/>
                        <path class="color-yellow"
                              d="M524.27,332.49,480.48,311.9v-.58l27.35-16.64h.31L552,315.27v.58l-27.41,16.62Zm-42.95-20.92,43.07,20.24,26.73-16.2L508,295.34Z"/>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" id="flechas_medio-linea" data-name="flechas medio-linea">
                        <path id="fle3-m" class="linea-blanca" d="M515,285.37,503.94,277l3.52-.42a.77.77,0,0,0-.18-1.52h0l-5.35.65-.28.1a.72.72,0,0,0-.34.46.58.58,0,0,0-.05.29l.66,5.34a.79.79,0,0,0,.87.65h0a.74.74,0,0,0,.64-.83v0h0l-.43-3.51,11,8.33a.75.75,0,0,0,1.07-.12h0A.8.8,0,0,0,515,285.37Z"/>
                        <path id="fle2-m" class="linea-blanca" d="M496.1,271.29,485.05,263l3.51-.37a.78.78,0,0,0,.65-.86.76.76,0,0,0-.86-.65h0l-5.33.63a1.48,1.48,0,0,0-.29.12.69.69,0,0,0-.34.44.57.57,0,0,0,0,.29l.65,5.35a.78.78,0,0,0,.86.65h0a.73.73,0,0,0,.64-.82v0h0l-.42-3.5,11.05,8.33a.76.76,0,0,0,1.07-.12h0a.8.8,0,0,0-.07-1.13Z"/>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" id="flechas_medio-linea2" data-name="flechas medio-linea2">
                        <path id="fle2-m-2" data-name="fle2-m" class="linea-blanca" d="M443.6,277.69l-11.05-8.33,3.5-.36a.78.78,0,0,0,.65-.86.76.76,0,0,0-.85-.66h0l-5.34.63a1,1,0,0,0-.28.11.7.7,0,0,0-.34.45.48.48,0,0,0,0,.29l.65,5.35a.77.77,0,0,0,.86.65h0a.74.74,0,0,0,.64-.83v0h0l-.42-3.49L442.62,279a.76.76,0,0,0,1.07-.12h0a.8.8,0,0,0-.07-1.13Z"/>
                        <path id="fle1-m" class="linea-blanca" d="M425.53,263.72l-11-8.33L418,255a.76.76,0,0,0,.65-.85h0a.78.78,0,0,0-.86-.65l-5.34.66a.88.88,0,0,0-.27.1.77.77,0,0,0-.35.46.8.8,0,0,0,0,.31l.69,5.43a.74.74,0,0,0,.83.64h0a.77.77,0,0,0,.65-.86h0l-.44-3.51L424.61,265a.74.74,0,0,0,1-.12v0a.76.76,0,0,0,0-1.07A.39.39,0,0,0,425.53,263.72Z"/>
                    </g>
                </g>
                <g id="central-linea">
                    <polygon class="linea-blanca"
                             points="542.39 332.87 542.61 332.5 632.84 385.44 632.62 385.81 542.39 332.87"/>
                    <circle class="color-blue" cx="542.39" cy="332.64" r="1"/>
                </g>
                <g id="conduccion-linea">
                    <rect class="linea-blanca" x="635.18" y="216.89" width="0.33" height="114.39"
                          transform="translate(-35.36 444.21) rotate(-37.57)"/>
                    <circle class="color-blue" cx="600.52" cy="228.82" r="1"/>
                </g>
                <g id="chimenea-linea">
                    <polygon class="linea-blanca"
                             points="513.22 130.26 513.46 129.94 580.35 175.52 580.11 175.85 513.22 130.26"/>
                    <ellipse class="color-blue" cx="580.15" cy="175.64" rx="0.95" ry="0.97"
                             transform="translate(347.98 735.56) rotate(-84.32)"/>
                </g>
                <g id="Tuberia">
                    <path class="linea-blanca"
                          d="M494.89,335v10.24a5.08,5.08,0,0,1-2.39,4.32l-80.6,51.21,3.59,5.33,82.27-52.31a7.49,7.49,0,0,0,3.51-6.34V338Z"/>
                    <path class="linea-blanca"
                          d="M535,307.47l54.88-33.77a5.86,5.86,0,0,0,2.74-4.95v-99.6a.87.87,0,0,0-.87-.86h-4.64a.87.87,0,0,0-.87.86v98.51a1.45,1.45,0,0,1-.67,1.22l-57.42,35.37Z"/>
                    <path class="linea-blanca"
                          d="M634.76,145.2c-.07,5.59-.26,11.44-.76,12.24l-46.21,69,.3,9.14,50.09-72.84c1.15-1.73,2.33-3.46,2.51-6v-19"/>
                    <path id="up-3" fill="url(#left-to-right-3)"
                          d="M638.94,140.82v15.9a10.16,10.16,0,0,1-2.15,5l-47.12,68.53-.11-3.26,45.94-68.6c1.26-2,1.15-6,1-13.87v-.2Z"/>
                </g>
                <g id="Tuberia-agua">
                    <path
                        id="up-1" fill="url(#left-to-right-1)"
                        d="M499.61,337.91v10a5.49,5.49,0,0,1-2.57,4.66l-81,50.52-1.36-2L494,351.74a7,7,0,0,0,3.32-6v-8.93Z"/>
                    <polygon class="color-blue" points="588.36 158.18 588.36 158.45 588.24 158.26 588.36 158.18"/>
                    <path id="up-2" fill="url(#left-to-right-2)"
                          d="M590.3,170.26V185l-.12.08.12.19v84a3.85,3.85,0,0,1-1.8,3.26l-55.7,33.74-2.56-1.2,56.08-34a3.44,3.44,0,0,0,1.61-2.91v-97.9Z"/>
                </g>
                <g id="fuentes">
                    <text class="txt-zona" transform="translate(635.08 391.38)">{{ $t('chiraCental2') }}</text>
                    <text class="txt-zona" transform="translate(635.08 373.37)">{{ $t('chiraCental1') }}</text>
                    <text class="txt-zona" transform="translate(635.08 409.37)">{{ $t('chiraCental3') }}</text>
                    <text class="txt-zona" transform="translate(672.36 334.56)">{{ $t('chiraConducción2') }}</text>
                    <text class="txt-zona" transform="translate(672.36 316.71)">{{ $t('chiraConducción1') }}</text>
                    <text class="txt-zona" transform="translate(600.42 90.14)">{{ $t('chiraSuperior1') }}</text>
                    <text class="txt-zona txt-zona-small" transform="translate(570.42 105.14)">{{ $t('chiraSuperior2') }}</text>
                    <text class="txt-zona" transform="translate(355.31 340.05)">{{ $t('chiraInferior1') }}</text>
                    <text class="txt-zona txt-zona-small" transform="translate(330.31 355.17)">{{ $t('chiraInferior2') }}</text>
                    <text class="txt-zona" transform="translate(427.28 141.89)">{{ $t('chiraChimenea2') }}</text>
                    <text class="txt-zona" transform="translate(445.38 123.78)">{{ $t('chiraChimenea1') }}</text>
                </g>
                <g id="tuberia-y">
                    <path class="color-yellow"
                          d="M594.72,171.82a3.12,3.12,0,0,0-2.12-2.67v2.67c0,.27-1.2,1.1-3.21,1.1s-3.17-.83-3.17-1.1v-2.69a3.15,3.15,0,0,0-2.16,2.69c0,1.88,2.29,3.3,5.33,3.3s5.33-1.42,5.33-3.3"/>
                    <path class="color-yellow"
                          d="M594.72,211.7a3.12,3.12,0,0,0-2.12-2.7v2.67c0,.27-1.2,1.1-3.21,1.1s-3.17-.83-3.17-1.1V209a3.14,3.14,0,0,0-2.16,2.69c0,1.88,2.29,3.3,5.33,3.3s5.33-1.42,5.33-3.3"/>
                    <path class="color-yellow"
                          d="M594.72,251.58a3.12,3.12,0,0,0-2.12-2.67v2.67c0,.27-1.2,1.1-3.21,1.1s-3.17-.83-3.17-1.1v-2.69a3.14,3.14,0,0,0-2.16,2.69c0,1.88,2.29,3.3,5.33,3.3s5.33-1.42,5.33-3.3"/>
                    <path class="color-yellow"
                          d="M564.08,292.18a3.11,3.11,0,0,0,1.21-3.19L563,290.35c-.24.14-1.56-.46-2.59-2.2s-.91-3.14-.67-3.28l2.31-1.37a3.13,3.13,0,0,0-3.42-.49c-1.61,1-1.67,3.66-.11,6.27s3.94,3.86,5.56,2.9"/>
                    <path class="color-yellow"
                          d="M503.37,344.36a3.12,3.12,0,0,0-2.12-2.67v2.67c0,.27-1.19,1.1-3.21,1.1s-3.16-.83-3.16-1.1v-2.69a3.13,3.13,0,0,0-2.17,2.69c0,1.88,2.3,3.3,5.33,3.3s5.33-1.42,5.33-3.3"/>
                    <path class="color-yellow"
                          d="M439.23,393.5a3.11,3.11,0,0,0,1.14-3.21l-2.27,1.41c-.23.15-1.56-.43-2.63-2.14s-1-3.12-.74-3.27l2.28-1.42a3.14,3.14,0,0,0-3.43-.41c-1.6,1-1.59,3.69,0,6.27s4,3.77,5.63,2.77"/>
                    <path class="color-yellow"
                          d="M417.74,407.13a3.12,3.12,0,0,0,1.14-3.21l-2.27,1.41c-.23.15-1.56-.43-2.63-2.14s-1-3.12-.74-3.27l2.28-1.42a3.12,3.12,0,0,0-3.43-.41c-1.6,1-1.59,3.69,0,6.27s4,3.77,5.63,2.77"/>
                    <path class="color-yellow"
                          d="M467.63,375.4a3.11,3.11,0,0,0,1.14-3.21l-2.26,1.41c-.23.15-1.57-.43-2.63-2.14s-1-3.12-.75-3.26l2.28-1.43a3.14,3.14,0,0,0-3.43-.41c-1.59,1-1.58,3.69,0,6.27s4,3.77,5.63,2.77"/>
                    <path class="color-yellow"
                          d="M642.59,151.86a2.86,2.86,0,0,0-2-2.46v2.46c0,.25-1.1,1-3,1s-2.92-.76-2.92-1v-2.48a2.87,2.87,0,0,0-2,2.48c0,1.73,2.11,3,4.91,3s4.9-1.31,4.9-3"/>
                </g>
                <g id="flechas_abajo" data-name="flechas abajo">
                    <path id="fle3-b" class="linea-blanca" d="M445.65,403.92l11.58-7.6-.8,3.46a.76.76,0,0,0,.53.93.75.75,0,0,0,.93-.53.14.14,0,0,0,0-.06l1.22-5.24a.76.76,0,0,0,0-.3.84.84,0,0,0-.3-.48l-.28-.14-5.24-1.2a.74.74,0,0,0-.9.54v0h0a.78.78,0,0,0,.58.92h0l3.46.8-11.58,7.6a.74.74,0,0,0-.26,1l0,0a.74.74,0,0,0,1,.24Z" />
                    <path id="fle2-b" class="linea-blanca" d="M465.35,391l11.58-7.6-.8,3.46a.76.76,0,0,0,.53.93.75.75,0,0,0,.93-.53.14.14,0,0,0,0-.06l1.22-5.24a.76.76,0,0,0,0-.3.84.84,0,0,0-.3-.48.7.7,0,0,0-.28-.12L473,379.84a.76.76,0,0,0-.9.56h0a.74.74,0,0,0,.52.91h0l3.46.8-11.58,7.6a.76.76,0,0,0,.8,1.28Z" />
                    <path id="fle1-b" class="linea-blanca" d="M484.69,378.84l11.56-7.6-.8,3.44a.74.74,0,0,0,.56.91h0A.78.78,0,0,0,497,375l1.2-5.24a.76.76,0,0,0,0-.3.77.77,0,0,0-.3-.5.68.68,0,0,0-.26-.12l-5.32-1.24a.78.78,0,0,0-.92.58h0a.79.79,0,0,0,.56.92h0l3.44.78-11.58,7.6a.76.76,0,0,0-.24,1.06.77.77,0,0,0,1.06.24h0Z" />
                </g>
                <g id="flechas_medio" data-name="flechas medio">
                    <path id="fle3-m" class="linea-blanca" d="M577.27,261.55V247.71l2.45,2.56a.76.76,0,0,0,1.1-1.06h0l-3.74-3.88-.24-.16a.71.71,0,0,0-.57,0,.72.72,0,0,0-.26.14l-3.86,3.74a.78.78,0,0,0,0,1.08h0a.73.73,0,0,0,1,0l0,0h0l2.54-2.46v13.84a.75.75,0,0,0,.73.78h0A.81.81,0,0,0,577.27,261.55Z" />
                    <path id="fle2-m" class="linea-blanca" d="M577.15,238V224.15l2.4,2.58a.77.77,0,0,0,1.07,0,.75.75,0,0,0,0-1.07h0l-3.71-3.88a1.4,1.4,0,0,0-.26-.16.71.71,0,0,0-.57,0,.6.6,0,0,0-.26.14l-3.88,3.74a.8.8,0,0,0,0,1.08h0a.74.74,0,0,0,1,0l0,0h0l2.54-2.44V238a.75.75,0,0,0,.73.78h0a.78.78,0,0,0,.85-.73Z" />
                    <path id="fle1-m" class="linea-blanca" d="M577.43,215.15V201.31l2.46,2.54a.76.76,0,0,0,1.07,0h0a.78.78,0,0,0,0-1.08l-3.74-3.86a1,1,0,0,0-.24-.16.71.71,0,0,0-.57,0,.76.76,0,0,0-.26.16l-3.92,3.82a.74.74,0,0,0,0,1.05h0a.78.78,0,0,0,1.08,0h0l2.54-2.46v13.86a.73.73,0,0,0,.71.76h0a.75.75,0,0,0,.83-.67A.57.57,0,0,0,577.43,215.15Z" />
                </g>
                <g id="flechas_arriba" data-name="flechas arriba">
                    <path id="fle2-a" class="linea-blanca" d="M627.92,195.29l8-11.24.5,3.52a.77.77,0,0,0,.86.64.76.76,0,0,0,.64-.86l-.74-5.32a.94.94,0,0,0-.1-.28.79.79,0,0,0-.46-.36h-.3l-5.32.74a.76.76,0,0,0-.66.85h0a.77.77,0,0,0,.86.64h0l3.52-.5-8,11.24a.76.76,0,0,0,0,1.07.75.75,0,0,0,1.07,0,.34.34,0,0,0,.09-.12Z" />
                    <path id="fle1-a" class="linea-blanca" d="M614.16,214.43l8-11.26.5,3.52a.76.76,0,0,0,.82.7.75.75,0,0,0,.7-.81.43.43,0,0,0,0-.11l-.74-5.32a.56.56,0,0,0-.1-.28.7.7,0,0,0-.46-.34.51.51,0,0,0-.3,0l-5.32.74a.76.76,0,0,0-.66.85h0a.78.78,0,0,0,.86.66h0l3.52-.48-8,11.24a.74.74,0,0,0,.12,1l0,0a.76.76,0,0,0,1.06-.18Z" />
                </g>
                <g id="arbol">
                    <g id="arbol-2">
                        <g id="tronco">
                            <path class="linea-blanca"
                                  d="M439.76,217.49h-2.22v-8.15h2.22ZM421.64,199.4h-2.22v-8.15h2.22ZM563,68.73h-2.22V62.6H563Zm95.39-19.08h-2.22V43.52h2.22Zm-14.63-5.28H641.5V38.24h2.22Z"/>
                        </g>
                    </g>
                    <path id="copa" class="color-blue"
                          d="M438.65,210.45a8.12,8.12,0,1,1,8.12-8.12h0A8.14,8.14,0,0,1,438.65,210.45Zm0-14a5.9,5.9,0,1,0,5.9,5.9h0a5.92,5.92,0,0,0-5.9-5.92h0Zm-18.12-4.07a8.12,8.12,0,1,1,8.12-8.12h0a8.14,8.14,0,0,1-8.12,8.1Zm0-14a5.9,5.9,0,1,0,5.9,5.9h0a5.92,5.92,0,0,0-5.88-5.94h0ZM561.85,63.71a6.39,6.39,0,1,1,6.39-6.39A6.39,6.39,0,0,1,561.85,63.71Zm0-10.56A4.17,4.17,0,1,0,566,57.34v0A4.16,4.16,0,0,0,561.85,53.15Zm95.39-8.52a6.39,6.39,0,1,1,6.39-6.39A6.39,6.39,0,0,1,657.24,44.63Zm0-10.56a4.17,4.17,0,1,0,4.17,4.17h0A4.17,4.17,0,0,0,657.24,34.07Zm-14.63,5.28A6.39,6.39,0,1,1,649,33v0A6.41,6.41,0,0,1,642.61,39.35Zm0-10.56A4.17,4.17,0,1,0,646.78,33a4.17,4.17,0,0,0-4.17-4.21Z"/>
                </g>
                <g id="nuves">
                    <g class="cls-18">
                        <path class="color-blue"
                              d="M345.24,76.91H306.4V75.8a6.23,6.23,0,0,1,11.05-4,10.07,10.07,0,0,1,18.62.24,5.33,5.33,0,0,1,9.17,3.71ZM337,74.69h5.83a3.12,3.12,0,0,0-5.83,0Zm-18,0h15.54a7.85,7.85,0,0,0-15.54,0Zm-10.16,0h7.71a4,4,0,0,0-7.71,0Z"/>
                        <path class="color-blue"
                              d="M411.53,97.69H387.69V96.58a5,5,0,0,1,8.67-3.5,8,8,0,0,1,15.17,3.5Zm-13.65-2.22h11.33a5.77,5.77,0,0,0-11.33,0Zm-7.74,0h5.19a2.82,2.82,0,0,0-5.19,0Z"/>
                    </g>
                </g>
            </g>
            <g id="bloque_right" data-name="bloque right">
                <rect id="R-rectangulo" class="linea-rectangulo" x="799.8" y="1.5" width="163.71" height="496.66"
                      rx="18.93" ry="18.93"/>
                <g id="termica" class="color-op2">
                    <polygon class="color-off"
                             points="899.11 143.01 872.37 158.15 830.84 132.96 857.57 117.82 899.11 143.01"/>
                    <line class="color-off" x1="899.11" y1="167.1" x2="899.11" y2="143.01"/>
                    <polyline class="color-off" points="830.84 132.96 830.84 160.78 872.37 185.98 887.98 177.13"/>
                    <line class="color-off" x1="872.37" y1="185.98" x2="872.37" y2="158.15"/>
                    <polyline class="color-off"
                              points="906.92 169.91 906.92 177.46 894.26 184.69 887.94 181.03 887.94 173.47"/>
                    <polygon class="color-off"
                             points="906.92 169.91 894.26 177.13 887.94 173.47 900.61 166.25 906.92 169.91"/>
                    <line class="color-off" x1="894.29" y1="184.69" x2="894.29" y2="177.14"/>
                    <line class="color-off" x1="895.32" y1="172.75" x2="895.32" y2="165.52"/>
                    <line class="color-off" x1="899.11" y1="170.83" x2="899.11" y2="163.61"/>
                    <polygon class="color-off"
                             points="882.95 163.41 892.46 157.6 892.46 164.35 882.95 170.15 882.95 163.41"/>
                    <line class="color-off" x1="852.02" y1="120.96" x2="850.4" y2="73.1"/>
                    <line class="color-off" x1="839.34" y1="73.29" x2="837.81" y2="129.01"/>
                    <polygon class="color-off" points="839.34 73.29 844.93 76.42 850.4 73.11 844.8 69.98 839.34 73.29"/>
                    <line class="color-off" x1="846.31" y1="124.18" x2="844.91" y2="76.42"/>
                    <ellipse class="color-off" cx="899.29" cy="73.96" rx="16.4" ry="8.73"/>
                    <path class="color-off"
                          d="M899.29,155.2c18.43,0,33.39-8,33.39-17.75,0-4.67-10.7-19.07-14.63-34.93A86.69,86.69,0,0,1,915.69,74"/>
                    <path class="color-off"
                          d="M882.88,74a86.73,86.73,0,0,1-2.36,28.53c-2.11,8.55-6.2,16.67-9.51,23.08"/>
                    <polygon class="color-off"
                             points="865.54 163.41 859.96 160.02 859.96 171.09 865.54 174.47 865.54 163.41"/>
                    <polygon class="color-off"
                             points="853.74 156.18 848.16 152.79 848.16 163.86 853.74 167.24 853.74 156.18"/>
                    <polygon class="color-off"
                             points="841.94 148.95 836.35 145.56 836.35 156.63 841.94 160.02 841.94 148.95"/>
                </g>
                <g id="eolica" class="color-op5">
                    <g id="eolC">
                        <g id="palaC" class="eolic-palas">
                            <polygon id="palaC-1" class="color-off" points="907.31 259.15 905.79 253.2 915.59 235.13 918.4 236.01 912.09 255.94 907.31 259.15" />
                            <polygon id="palaC-2" class="color-off" points="901 259.95 907.31 259.15 904.93 265.04 887.01 273.06 885.25 270.71 901 259.95" />
                            <polygon id="palaC-3" class="color-off" points="907.31 259.17 913.45 258.96 928.97 272.44 927.51 274.98 909.41 264.52 907.31 259.17" />
                        </g>
                        <path id="culC" class="color-off" d="M902.62,259.77a32.47,32.47,0,0,1-3.11-1.8c-3-1.89-.39-7.05,3.21-4.87a30.79,30.79,0,0,1,3.64,2.38" />
                        <path id="torreC" class="color-off" d="M904.64,265.18,903.09,304a5.14,5.14,0,0,0,3,1.21,3.82,3.82,0,0,0,2.73-1.3l-.45-42.17" />
                        <path id="lineamovC-1" class="color-off" d="M921.86,263.6a13.88,13.88,0,0,0,.14-12.86" />
                        <path id="lineamovC-2" class="color-off" d="M926.52,265.26a18.37,18.37,0,0,0-.43-17.24" />
                    </g>
                    <g id="eolB">
                        <g id="palasB" class="eolic-palas">
                            <polygon id="palaB-1" class="color-off" points="855.69 268.76 854.16 262.81 863.97 244.74 866.78 245.62 860.47 265.55 855.69 268.76" />
                            <polygon id="palaB-3" class="color-off" points="855.71 268.79 861.85 268.58 877.37 282.06 875.91 284.61 857.81 274.14 855.71 268.79" />
                            <polygon id="palaB-2" class="color-off" points="849.38 269.56 855.69 268.76 853.31 274.65 835.38 282.66 833.62 280.32 849.38 269.56" />
                        </g>
                        <path id="culB" class="color-off" d="M851,269.36a33.51,33.51,0,0,1-3.1-1.8c-3-1.89-.39-7.05,3.21-4.87a30.79,30.79,0,0,1,3.64,2.38" />
                        <path id="torreB" class="color-off" d="M853,274.79l-1.54,38.78a5.18,5.18,0,0,0,3,1.21,3.82,3.82,0,0,0,2.73-1.3l-.45-42.17" />
                        <path id="lineamovB-2" class="color-off" d="M845.47,250.18a18.38,18.38,0,0,0-10.42,13.75" />
                        <path id="lineamovB-1" class="color-off" d="M848.08,254.41a13.86,13.86,0,0,0-8.13,10" />
                    </g>
                    <g id="eolA">
                        <g id="palasA" class="eolic-palas">
                            <polygon id="palaA-1" class="color-off" points="883.55 230.83 882.02 224.88 891.83 206.81 894.63 207.69 888.32 227.62 883.55 230.83" />
                            <polygon id="palaA-3" class="color-off" points="883.54 230.85 889.68 230.64 905.21 244.12 903.75 246.67 885.65 236.2 883.54 230.85" />
                            <polygon id="palaA-2" class="color-off" points="877.23 231.63 883.55 230.83 881.16 236.72 863.24 244.74 861.48 242.39 877.23 231.63" />
                        </g>
                        <path id="torreA" class="color-off" d="M880.89,236.81l-1.55,38.78a5.21,5.21,0,0,0,3,1.21,3.82,3.82,0,0,0,2.73-1.3l-.45-42.17" />
                        <path id="culA" class="color-off" d="M878.87,231.4a32.47,32.47,0,0,1-3.11-1.8c-3-1.89-.39-7,3.21-4.87a30.79,30.79,0,0,1,3.64,2.38" />
                        <path id="lineamovA-1" class="color-off" d="M903.25,235.68a15.28,15.28,0,0,0-2.8-14.06" />
                        <path id="lineamovA-2" class="color-off" d="M899.19,235a11.48,11.48,0,0,0-1.69-10.56" />
                        <path id="lineamoaA-4" class="color-off" d="M873.9,212.22A18.37,18.37,0,0,0,863.48,226" />
                        <path id="lineamovA-3" class="color-off" d="M876.51,216.42a13.9,13.9,0,0,0-8.16,10" />
                    </g>
                </g>
                <g id="solar" class="color-op2">
                    <g id="panel_grande" data-name="panel grande">
                        <line class="color-off" x1="855.07" y1="424.35" x2="845.76" y2="424.35"/>
                        <line class="color-off" x1="848.22" y1="408.73" x2="848.22" y2="424.35"/>
                        <line class="color-off" x1="853.1" y1="416.55" x2="853.1" y2="424.35"/>
                        <polygon class="color-off"
                                 points="832.55 383.92 852.05 383.92 872.65 416.55 853.14 416.55 832.55 383.92"/>
                        <line class="color-off" x1="838.57" y1="393.46" x2="858.07" y2="393.46"/>
                        <line class="color-off" x1="845.55" y1="404.52" x2="865.06" y2="404.52"/>
                        <line class="color-off" x1="874.51" y1="424.35" x2="865.2" y2="424.35"/>
                        <line class="color-off" x1="867.67" y1="416.55" x2="867.67" y2="424.35"/>
                        <line class="color-off" x1="872.55" y1="416.55" x2="872.55" y2="424.35"/>
                        <polygon class="color-off"
                                 points="851.98 383.92 871.5 383.92 892.09 416.55 872.58 416.55 851.98 383.92"/>
                        <line class="color-off" x1="858.01" y1="393.46" x2="877.52" y2="393.46"/>
                        <line class="color-off" x1="864.99" y1="404.52" x2="884.5" y2="404.52"/>
                        <line class="color-off" x1="893.95" y1="424.35" x2="884.63" y2="424.35"/>
                        <line class="color-off" x1="887.1" y1="416.55" x2="887.1" y2="424.35"/>
                        <line class="color-off" x1="891.98" y1="416.55" x2="891.98" y2="424.35"/>
                        <polygon class="color-off"
                                 points="871.42 383.92 890.93 383.92 911.53 416.55 892.02 416.55 871.42 383.92"/>
                        <line class="color-off" x1="877.45" y1="393.46" x2="896.96" y2="393.46"/>
                        <line class="color-off" x1="884.43" y1="404.52" x2="903.94" y2="404.52"/>
                        <line class="color-off" x1="913.39" y1="424.35" x2="904.08" y2="424.35"/>
                        <line class="color-off" x1="906.54" y1="416.55" x2="906.54" y2="424.35"/>
                        <line class="color-off" x1="911.42" y1="416.55" x2="911.42" y2="424.35"/>
                        <polygon class="color-off"
                                 points="890.87 383.92 910.37 383.92 930.97 416.55 911.46 416.55 890.87 383.92"/>
                        <line class="color-off" x1="896.89" y1="393.46" x2="916.4" y2="393.46"/>
                        <line class="color-off" x1="903.87" y1="404.52" x2="923.38" y2="404.52"/>
                    </g>
                    <g id="sol">
                        <circle class="color-off" cx="854.78" cy="357.92" r="8.47"/>
                        <line class="color-off" x1="854.78" y1="344.85" x2="854.78" y2="340.34"/>
                        <line class="color-off" x1="854.78" y1="375.49" x2="854.78" y2="370.98"/>
                        <line class="color-off" x1="867.84" y1="357.92" x2="872.36" y2="357.92"/>
                        <line class="color-off" x1="837.2" y1="357.92" x2="841.72" y2="357.92"/>
                        <line class="color-off" x1="845.55" y1="348.68" x2="842.36" y2="345.49"/>
                        <line class="color-off" x1="867.22" y1="370.35" x2="864.02" y2="367.16"/>
                        <line class="color-off" x1="864.02" y1="348.68" x2="867.22" y2="345.49"/>
                        <line class="color-off" x1="842.36" y1="370.35" x2="845.55" y2="367.16"/>
                    </g>
                </g>
            </g>
            <g id="bloque_left" data-name="bloque left">
                <rect id="L-rectangulo" class="linea-rectangulo" x="1.5" y="1.5" width="195.01" height="496.66"
                      rx="18.93" ry="18.93"/>
                <g id="L-consumo">
                    <g id="fabrica" class="color-op3">
                        <polygon class="color-ventanas"
                                 points="110.81 95.55 93.8 105.79 93.8 99.53 110.81 89.29 110.81 95.55"/>
                        <polygon class="color-off"
                                 points="110.81 106.72 93.8 116.95 93.8 110.7 110.81 100.47 110.81 106.72"/>
                        <polyline class="color-off"
                                  points="38.91 111.85 18.96 100.63 18.96 76.44 50.79 76.44 50.79 95.86 86.65 95.55 115.65 78.73 115.65 121.35 86.65 138.89 56.1 121.84"/>
                        <line class="color-off" x1="96.07" y1="78.77" x2="115.65" y2="78.73"/>
                        <polyline class="color-off"
                                  points="18.96 76.44 48.34 59.37 80.16 59.37 80.16 78.79 86.65 78.78"/>
                        <line class="color-off" x1="86.65" y1="138.89" x2="86.65" y2="95.55"/>
                        <line class="color-off" x1="50.79" y1="76.43" x2="80.16" y2="59.37"/>
                        <line class="color-off" x1="50.79" y1="95.86" x2="80.16" y2="78.79"/>
                        <polyline class="color-off" points="86.65 50.9 86.65 87.32 91.42 87.32 96.07 84.5 96.07 50.75"/>
                        <polygon class="color-off" points="86.65 50.9 91.42 53.57 96.07 50.75 91.3 48.08 86.65 50.9"/>
                        <line class="color-off" x1="91.4" y1="87.32" x2="91.4" y2="53.57"/>
                        <polyline class="color-off"
                                  points="38.91 111.85 38.91 119.78 49.47 125.69 56.1 121.84 56.1 113.91"/>
                        <polygon class="color-off"
                                 points="38.91 111.85 49.47 117.75 56.1 113.91 45.54 108 38.91 111.85"/>
                        <line class="color-off" x1="49.44" y1="125.69" x2="49.44" y2="117.76"/>
                        <line class="color-off" x1="46.33" y1="105.2" x2="46.33" y2="112.21"/>
                        <line class="color-off" x1="49.44" y1="106.89" x2="49.44" y2="113.91"/>
                    </g>
                    <g id="fabricas_02" data-name="fabricas 02" class="color-op2">
                        <polyline class="color-off"
                                  points="139.28 174.65 127.67 167.98 104.18 182.35 132.82 198.78 145.87 190.81 145.87 183.75"/>
                        <line class="color-off" x1="104.18" y1="191.72" x2="104.18" y2="182.35"/>
                        <polyline class="color-off" points="156.31 177.62 156.31 201.89 132.82 216.25 126.8 212.79"/>
                        <line class="color-off" x1="132.82" y1="198.78" x2="132.82" y2="215.82"/>
                        <polygon class="color-off"
                                 points="134.7 177.42 145.87 183.82 156.28 177.49 145.11 171.09 134.7 177.42"/>
                        <polyline class="color-off" points="134.7 177.42 134.7 184.34 145.87 190.74"/>
                        <polygon class="color-off"
                                 points="136.61 203.37 136.61 200.64 138.31 199.61 138.31 202.34 136.61 203.37"/>
                        <polygon class="color-ventanas"
                                 points="140.29 201.09 140.29 198.36 141.97 197.33 141.97 200.07 140.29 201.09"/>
                        <polygon class="color-off"
                                 points="143.96 198.81 143.96 196.08 145.64 195.06 145.64 197.79 143.96 198.81"/>
                        <polygon class="color-ventanas"
                                 points="147.62 196.53 147.62 193.8 149.32 192.78 149.32 195.51 147.62 196.53"/>
                        <polygon class="color-ventanas"
                                 points="151.3 194.26 151.3 191.52 152.99 190.5 152.99 193.23 151.3 194.26"/>
                        <polygon class="color-off"
                                 points="136.61 209.4 136.61 206.67 138.31 205.65 138.31 208.38 136.61 209.4"/>
                        <polygon class="color-ventanas"
                                 points="140.29 207.13 140.29 204.39 141.97 203.37 141.97 206.1 140.29 207.13"/>
                        <polygon class="color-ventanas"
                                 points="143.96 204.84 143.96 202.11 145.64 201.09 145.64 203.82 143.96 204.84"/>
                        <polygon class="color-off"
                                 points="147.62 202.57 147.62 199.84 149.32 198.81 149.32 201.54 147.62 202.57"/>
                        <polygon class="color-off"
                                 points="151.3 200.29 151.3 197.56 152.99 196.53 152.99 199.26 151.3 200.29"/>
                        <polyline class="color-off"
                                  points="109.76 195.27 98.15 188.61 74.66 202.98 103.31 219.41 116.35 211.43 116.35 204.37"/>
                        <polyline class="color-off"
                                  points="126.8 198.24 126.8 222.51 103.31 236.88 74.66 220.44 74.66 202.98"/>
                        <line class="color-off" x1="103.31" y1="219.41" x2="103.31" y2="236.44"/>
                        <polygon class="color-off"
                                 points="105.19 198.05 116.35 204.44 126.76 198.12 115.6 191.72 105.19 198.05"/>
                        <polyline class="color-off" points="105.19 198.05 105.19 204.97 116.35 211.37"/>
                        <polygon class="color-off"
                                 points="107.1 223.99 107.1 221.26 108.79 220.23 108.79 222.97 107.1 223.99"/>
                        <polygon class="color-ventanas"
                                 points="110.77 221.71 110.77 218.98 112.46 217.96 112.46 220.69 110.77 221.71"/>
                        <polygon class="color-off"
                                 points="114.44 219.44 114.44 216.71 116.13 215.68 116.13 218.41 114.44 219.44"/>
                        <polygon class="color-ventanas"
                                 points="118.11 217.16 118.11 214.43 119.8 213.4 119.8 216.13 118.11 217.16"/>
                        <polygon class="color-ventanas"
                                 points="121.78 214.88 121.78 212.15 123.47 211.13 123.47 213.86 121.78 214.88"/>
                        <polygon class="color-off"
                                 points="107.1 230.03 107.1 227.29 108.79 226.27 108.79 229 107.1 230.03"/>
                        <polygon class="color-ventanas"
                                 points="110.77 227.75 110.77 225.02 112.46 223.99 112.46 226.72 110.77 227.75"/>
                        <polygon class="color-ventanas"
                                 points="114.44 225.47 114.44 222.74 116.13 221.71 116.13 224.45 114.44 225.47"/>
                        <polygon class="color-off"
                                 points="118.11 223.2 118.11 220.46 119.8 219.44 119.8 222.17 118.11 223.2"/>
                        <polygon class="color-off"
                                 points="121.78 220.91 121.78 218.19 123.47 217.16 123.47 219.89 121.78 220.91"/>
                    </g>
                    <g id="casas_02" data-name="casas 02" class="color-op1">
                        <polygon class="color-off"
                                 points="114.72 293.46 149.7 313.53 175.19 298.06 140.21 278 114.72 293.46"/>
                        <line class="color-off" x1="114.72" y1="339.43" x2="114.72" y2="293.46"/>
                        <polyline class="color-off" points="175.19 298.06 175.19 382.49 171.83 384.53"/>
                        <line class="color-off" x1="149.7" y1="313.53" x2="149.7" y2="369.53"/>
                        <polyline class="color-ventanas" points="155.24 331.67 155.24 319.93 157.39 318.63"/>
                        <polyline class="color-ventanas" points="161.57 327.86 161.57 316.12 163.71 314.81"/>
                        <polyline class="color-ventanas" points="168.01 324.02 168.01 312.27 170.16 310.97"/>
                        <polyline class="color-off" points="155.24 350.21 155.24 338.47 157.39 337.16"/>
                        <polyline class="color-ventanas" points="161.57 346.4 161.57 334.65 163.71 333.35"/>
                        <polyline class="color-ventanas" points="168.01 342.55 168.01 330.81 170.16 329.51"/>
                        <polyline class="color-ventanas" points="155.24 368.75 155.24 357 157.39 355.7"/>
                        <polyline class="color-off" points="161.57 364.94 161.57 353.19 163.71 351.88"/>
                        <polyline class="color-off" points="168.01 361.09 168.01 349.35 170.16 348.04"/>
                        <polygon class="color-off"
                                 points="105.88 409.82 86.71 398.82 86.71 355.17 118.19 337.63 127.63 353.59 127.63 396.93 105.88 409.82"/>
                        <polyline class="color-off" points="118.19 337.63 127.63 353.59 105.88 365.64 86.71 355.17"/>
                        <polyline class="color-off" points="105.88 409.82 105.88 365.64 98.48 348.61"/>
                        <line class="color-off" x1="111.49" y1="372.52" x2="111.49" y2="369.2"/>
                        <line class="color-ventanas" x1="111.49" y1="381.8" x2="111.49" y2="378.49"/>
                        <line class="color-ventanas" x1="111.49" y1="391.08" x2="111.49" y2="387.77"/>
                        <line class="color-ventanas" x1="117.2" y1="369.28" x2="117.2" y2="365.96"/>
                        <line class="color-ventanas" x1="117.2" y1="378.56" x2="117.2" y2="375.25"/>
                        <line class="color-ventanas" x1="117.2" y1="387.85" x2="117.2" y2="384.53"/>
                        <line class="color-ventanas" x1="122.9" y1="366.05" x2="122.9" y2="362.73"/>
                        <line class="color-off" x1="122.9" y1="375.33" x2="122.9" y2="372.02"/>
                        <line class="color-ventanas" x1="122.9" y1="384.61" x2="122.9" y2="381.3"/>
                        <line class="color-ventanas" x1="101.54" y1="372.52" x2="101.54" y2="369.2"/>
                        <line class="color-off" x1="101.54" y1="381.8" x2="101.54" y2="378.49"/>
                        <line class="color-ventanas" x1="95.84" y1="369.28" x2="95.84" y2="365.96"/>
                        <line class="color-ventanas" x1="95.84" y1="378.56" x2="95.84" y2="375.25"/>
                        <line class="color-off" x1="90.13" y1="366.05" x2="90.13" y2="362.73"/>
                        <line class="color-ventanas" x1="90.13" y1="375.33" x2="90.13" y2="372.02"/>
                        <polygon class="color-off"
                                 points="93.01 402.35 97.68 405.02 97.68 394.48 93.01 391.93 93.01 402.35"/>
                        <polyline class="color-off"
                                  points="134.19 381.36 127.6 385.32 155.51 401.1 177.69 387.76 171.16 384.07"/>
                        <polyline class="color-off"
                                  points="177.69 387.76 177.69 431.06 155.51 444.4 127.6 428.63 127.6 385.32"/>
                        <line class="color-off" x1="155.52" y1="401.1" x2="155.52" y2="444.4"/>
                        <polyline class="color-off"
                                  points="134.19 378.98 134.19 384.18 155.58 396.45 171.16 387.01 171.16 381.8"/>
                        <polygon class="color-off"
                                 points="134.19 378.98 155.58 391.25 171.16 381.8 149.78 369.53 134.19 378.98"/>
                        <polyline class="color-off" points="166.75 384.47 149.78 374.74 138.6 381.51"/>
                        <line class="color-off" x1="149.78" y1="374.74" x2="149.78" y2="369.53"/>
                        <line class="color-off" x1="155.52" y1="396.45" x2="155.52" y2="391.24"/>
                        <polyline class="color-off" points="148.67 417.68 148.67 405 146.35 403.59"/>
                        <polyline class="color-ventanas" points="141.84 413.56 141.84 400.89 139.51 399.47"/>
                        <polyline class="color-ventanas" points="134.88 409.42 134.88 396.74 132.56 395.32"/>
                        <polygon class="color-off"
                                 points="42.12 394.87 22.95 383.88 22.95 340.21 54.43 322.69 63.86 338.65 63.86 381.98 42.12 394.87"/>
                        <polyline class="color-off" points="54.43 322.69 63.86 338.65 42.12 350.7 22.95 340.21"/>
                        <polyline class="color-off" points="42.12 394.87 42.12 350.7 34.73 333.66"/>
                        <line class="color-off" x1="47.73" y1="357.57" x2="47.73" y2="354.26"/>
                        <line class="color-ventanas" x1="47.73" y1="366.85" x2="47.73" y2="363.55"/>
                        <line class="color-ventanas" x1="53.44" y1="354.34" x2="53.44" y2="351.02"/>
                        <line class="color-ventanas" x1="53.44" y1="363.62" x2="53.44" y2="360.31"/>
                        <line class="color-ventanas" x1="59.14" y1="351.1" x2="59.14" y2="347.79"/>
                        <line class="color-off" x1="59.14" y1="360.38" x2="59.14" y2="357.07"/>
                        <line class="color-ventanas" x1="37.78" y1="357.57" x2="37.78" y2="354.26"/>
                        <line class="color-off" x1="37.78" y1="366.85" x2="37.78" y2="363.55"/>
                        <line class="color-ventanas" x1="32.08" y1="354.34" x2="32.08" y2="351.02"/>
                        <line class="color-ventanas" x1="32.08" y1="363.62" x2="32.08" y2="360.31"/>
                        <line class="color-off" x1="26.37" y1="351.1" x2="26.37" y2="347.79"/>
                        <line class="color-ventanas" x1="26.37" y1="360.38" x2="26.37" y2="357.07"/>
                        <polygon class="color-off"
                                 points="56.78 386.17 52.12 388.85 52.12 378.31 56.78 375.76 56.78 386.17"/>
                        <line class="color-off" x1="33.8" y1="334.17" x2="33.8" y2="327.37"/>
                        <polygon class="color-off"
                                 points="49.57 325.4 39.78 330.85 33.8 327.37 43.57 321.92 49.57 325.4"/>
                        <line class="color-off" x1="40.51" y1="327.36" x2="40.51" y2="320.34"/>
                        <line class="color-off" x1="43.56" y1="325.81" x2="43.56" y2="318.79"/>
                        <polyline class="color-off"
                                  points="62.09 289.18 49.7 296.64 78.77 313.07 101.88 299.17 90.84 292.94"/>
                        <line class="color-ventanas" x1="82.72" y1="317.06" x2="98.44" y2="307.6"/>
                        <line class="color-off" x1="82.72" y1="323.07" x2="98.44" y2="313.61"/>
                        <line class="color-ventanas" x1="82.72" y1="329.07" x2="98.44" y2="319.61"/>
                        <line class="color-ventanas" x1="82.72" y1="335.08" x2="98.44" y2="325.62"/>
                        <line class="color-off" x1="82.72" y1="341.09" x2="98.44" y2="331.63"/>
                        <line class="color-off" x1="82.72" y1="347.09" x2="98.44" y2="337.63"/>
                        <line class="color-ventanas" x1="79.91" y1="280.96" x2="87.77" y2="276.23"/>
                        <line class="color-off" x1="79.91" y1="285.66" x2="87.77" y2="280.93"/>
                        <line class="color-ventanas" x1="79.91" y1="290.37" x2="87.77" y2="285.64"/>
                        <line class="color-ventanas" x1="79.91" y1="295.07" x2="87.77" y2="290.34"/>
                        <line class="color-off" x1="49.7" y1="325.18" x2="49.7" y2="296.64"/>
                        <polyline class="color-off" points="86.71 371.13 78.77 375.91 63.86 367.48"/>
                        <line class="color-off" x1="101.87" y1="299.17" x2="101.87" y2="346.4"/>
                        <line class="color-off" x1="78.77" y1="313.07" x2="78.77" y2="375.91"/>
                        <polyline class="color-off"
                                  points="62.09 268.65 62.09 298.59 76.64 306.72 90.84 298.11 90.84 268.17"/>
                        <polygon class="color-off"
                                 points="62.09 268.65 76.64 276.78 90.84 268.17 76.29 260.04 62.09 268.65"/>
                        <line class="color-off" x1="76.58" y1="306.72" x2="76.58" y2="276.78"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "inphograpie",
        created() {

        }
    }
</script>

<style lang="scss" scoped>
    #chira-svg-complete {
        .txt-zona {
            fill: #fff;
            font-size: 15px;
            letter-spacing: 0.05em;
            isolation: isolate;
            &.txt-zona-small {
                font-size: 12px;
            }
        }
        .cls-1b {
            fill: none;
        }
        .linea-rectangulo {
            fill: none;
            stroke: #50d2d2;
            stroke-miterlimit: 10;
            stroke-width: 3px;
        }
        .linea-tension-y {
            fill: none;
            stroke: #50d2d2;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
        }
        .linea-tension-y-r {
            fill: none;
            stroke: #50d2d2;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
            stroke-dasharray: 3;
        }
        .marco-zona {
            fill: none;
            stroke: #50d2d2;
            stroke-miterlimit: 10;
            stroke-width: 3px;
        }
        .linea-blanca {
            fill: #fff;
        }
        .Sombra {
            fill: #fff;
            opacity: 0.43;
            isolation: isolate;
        }
        .color-blue {
            fill: #00a7d4;
        }
        .agua {
            fill: #00a7d4;
            opacity: 0.57;
            isolation: isolate;
        }
        .cls-7b {
            clip-path: url(#clip-path);
        }
        .color-yellow {
            fill: #50d2d2;
        }
        .color-ventanas {fill: none;stroke: #00a2a4;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px;}
        .color-on {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
        }
        .color-off {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
        }
        .color-op5 {
            opacity: 0.97;
        }
        .color-op4 {
            opacity: 0.75;
        }
        .color-op3 {
            opacity: 0.55;
        }
        .color-op2 {
            opacity: 0.35;
        }
        .color-op1 {
            opacity: 0.25;
        }
        .cls-43b {
            stroke: #00a2a4;
        }

        #embalse2 {
            .agua {
                animation: downWatter 10s;
                transform: scaleY(1);
                opacity: 1;
            }
        }

        #embalse1 {
            .agua {
                animation: upWatter 10s;
                transform: scaleY(1.5);
                opacity: 0;
            }
        }
        #flechas_medio-linea,
        #flechas_medio-linea2 {
            path {
                transform-box: fill-box;
                transform: rotate(180deg);
                opacity: 0;
                animation: opacity 2s infinite;
            }
            path:nth-child(1) {
                animation-delay: 0.3s
            }
            path:nth-child(2) {
                animation-delay: 0s
            }
        }
        #flechas_abajo {
            path {
                animation: opacity 3.5s infinite;
            }
            path:nth-child(1) {
                animation-delay: 0s
            }
            path:nth-child(2) {
                animation-delay: 0.3s
            }
            path:nth-child(3) {
                animation-delay: 0.6s
            }
        }
        #flechas_medio {
            path {
                opacity: 0;
                animation: opacity 3.5s infinite;
                animation-delay: 1s;
            }
            path:nth-child(1) {
                animation-delay: 1.2s
            }
            path:nth-child(2) {
                animation-delay: 1.5s
            }
            path:nth-child(3) {
                animation-delay: 1.8s
            }
        }
        #flechas_arriba {
            path {
                opacity: 0;
                animation: opacity 3.5s infinite;
                animation-delay: 2s;
            }
            path:nth-child(1) {
                animation-delay: 2.2s
            }
            path:nth-child(2) {
                animation-delay: 2.5s
            }
            path:nth-child(3) {
                animation-delay: 2.8s
            }
        }

        .bateria-box {
            opacity: 0;
            animation: opacity 2.5s infinite;
            &#bateria-box-1 {
                animation-delay: 1.2s
            }
            &#bateria-box-2 {
                animation-delay: 1.5s
            }
            &#bateria-box-3 {
                animation-delay: 1.8s
            }
            &#bateria-box-4 {
                animation-delay: 2.1s
            }
        }
    }

    @keyframes downWatter {
        0% {
            transform: scaleY(1);
            opacity: 1;
        }
        100% {
            transform: scaleY(1.05);
            opacity: 0.5;
        }
    }

    @keyframes upWatter {
        0% {
            transform: scaleY(1.5);
            opacity: 0.3;
        }
        100% {
            transform: scaleY(1);
            opacity: 1;
        }
    }

    @-webkit-keyframes opacityWater {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes opacity {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }

    }

</style>