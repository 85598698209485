<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 180 98.1" style="enable-background:new 0 0 180 98.1;" xml:space="preserve">
    <g id="Capa_2_1_">
        <g id="BOTONERA">
            <g id="bombeo">
                <path id="eolica" class="icon-btn" d="M31.3,34l-2.1-8.2L42.6,1l3.8,1.2l-8.6,27.4L31.3,34z M1,49.8L3.4,53L28,42l3.3-8l-8.7,1.1
                    L1,49.8z M31.3,34l0.6,7.8l10.7,13l2.7-1.8l-7-14.8L31.3,34z M30.6,31.2c0,0-3.8-2.8-8.3-5.5c-4.9-3-8.5,4-4.4,6.6
                    c1.5,1,3.1,1.9,4.7,2.7 M23.3,44.4l-2,51c1.2,1,2.6,1.6,4.1,1.7c1.4-0.1,2.8-0.7,3.8-1.8l-0.6-54.8 M54.9,42.4
                    c3.8-7.5,3.5-16.4-0.6-23.7 M48.5,40.1c3-5.5,3-12.1,0.2-17.6 M11,13.5C4.9,19.3,2.1,27.7,3.6,36 M16.3,17.9
                    c-4.6,4.2-6.9,10.3-6.2,16.5"/>
                <path id="pila_1_" class="icon-btn" d="M179,24.8c0-3-2.5-5.5-5.5-5.5h0h-33.2c-3,0-5.5,2.4-5.5,5.4c0,0,0,0,0,0v61.8
                    c0,3,2.5,5.4,5.5,5.4h33.2c3,0,5.5-2.4,5.5-5.4V24.8z M166.9,11.3h-20v8h19.9L166.9,11.3z"/>
                <path id="pila-r1" class="icon-btn" d="M171.9,85.7h-30v-8.5h30V85.7z"/>
                <path id="pila-r2" class="icon-btn" d="M171.9,77.1h-30v-8.5h30V77.1z"/>
                <path id="pila-r3" class="icon-btn" d="M171.9,68.6h-30V60h30V68.6z"/>
                <path id="pila-r4" class="icon-btn" d="M171.9,60h-30v-8.5h30V60z"/>
                <path id="pila-r5" class="icon-btn" d="M171.9,51.5h-30v-8.5h30V51.5z"/>
                <path id="pila-r6" class="icon-btn" d="M171.9,42.9h-30v-8.5h30V42.9z"/>
                <path id="pila-r7" class="icon-btn" d="M171.9,34.7h-30v-8.5h30V34.7z"/>
                <path id="flechas" class="icon-flecha" d="M75.7,56.4L75.7,56.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0
                    c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0
                    L75.7,56.4z M81.3,56.4L81.3,56.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0
                    c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L81.3,56.4L81.3,56.4z
                     M86.7,56.4L86.7,56.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4
                    l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L86.7,56.4z M92.3,56.4L92.3,56.4
                    c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3
                    c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L92.3,56.4L92.3,56.4z M97.7,56.4L97.7,56.4
                    c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3
                    c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L97.7,56.4z M103.3,56.4L103.3,56.4c0.4,0.4,0.4,1,0,1.4
                    c0,0,0,0,0,0l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4
                    c0,0,0,0,0,0c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L103.3,56.4L103.3,56.4z M108.7,56.4L108.7,56.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0
                    l-3,3l0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0
                    c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L108.7,56.4z M114.3,56.4L114.3,56.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-3,3l0,0
                    c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0l0,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3l-2.3-2.3c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0
                    c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0L114.3,56.4L114.3,56.4z"/>
            </g>
        </g>
    </g>
    </svg>
</template>

<script>
    export default {
        name: "btn-proceso-bombeo"
    }
</script>

<style scoped>
    .icon-btn{fill:none;stroke:#94aab4;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
    .icon-flecha{fill:#50d2d2;}
</style>