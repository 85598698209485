<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.41 67.12">
        <defs>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_5" data-name="Capa 5">
                <path id="icon-salto" class="icon-color" d="M54.67,5.73a3.34,3.34,0,0,0,4.74,0l0,0a3.77,3.77,0,0,0,.71-1.1,3.54,3.54,0,0,0,.27-1.28,3.37,3.37,0,0,0-1-2.38l-.5-.41a3.36,3.36,0,0,0-.6-.3,3.34,3.34,0,0,0-4.64,3.09A3.54,3.54,0,0,0,54,4.63,3.77,3.77,0,0,0,54.67,5.73Zm2.38,4.33a3.37,3.37,0,0,0-3.36,3.36V26.84a3.36,3.36,0,1,0,6.72,0V13.42A3.37,3.37,0,0,0,57.05,10.06Zm.2,26.85a3.36,3.36,0,0,0-3.72,2.92A23.52,23.52,0,0,1,30.2,60.41H11.44l2.15-2.12a3.36,3.36,0,0,0,0-4.73A23.49,23.49,0,0,1,30.2,13.42a23.13,23.13,0,0,1,11.71,3.15,3.36,3.36,0,0,0,3.36-5.77A29.68,29.68,0,0,0,30.2,6.71,30.2,30.2,0,0,0,6.71,55.78L1,61.38a3.37,3.37,0,0,0,2.38,5.74H30.2a30.2,30.2,0,0,0,30-26.45,3.34,3.34,0,0,0-2.9-3.75Z" />
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "icon-salto"
    }
</script>

<style scoped>

</style>