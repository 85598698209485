<template>
    <div id="header-buttons">
        <div class="header-button" @click="$emit('step-back')">
            <img src="./assets/btn-volver.svg" alt="">
        </div>
        <div class="header-button" @click="$emit('back-home')">
            <img src="./assets/btn-home.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "headers-buttons"
    }
</script>

<style lang="scss" scoped>
    #header-buttons {
        z-index: 999;
        position: fixed;
        top: 40px;
        left: 40px;
        display: flex;
        align-items: center;
        .header-button {
            cursor: pointer;
            width: 50px;
            height: auto;
            &:first-child {
                margin-right: 15px;
            }
        }

    }
</style>