<template>
    <div id="app" @click="userTouch">
        <HeaderButtons v-if="step > 0" @step-back="stepBack" @back-home="backHome"/>

        <section class="particles">
            <canvas id="canvas"></canvas>
        </section>


        <Intro v-if="step === 0" :requested-language="requestedLanguage" @language-selected="languageSelected"/>

        <Buttons v-if="step === 1" @button-selected="buttonSelected"/>

        <CentralFunctioning v-if="step === 2" @button-selected="buttonSelected"/>

        <BombeoProcress  v-if="step === 3" @button-selected="buttonSelected"/>
        <TurbinacionProcress  v-if="step === 4" @button-selected="buttonSelected"/>

        <VideoLoad @video-reproduction="time = 25" :video-name = "'chira'" v-if="step === 5" />
        <VideoLoad @video-reproduction="time = 25" :video-name = "'testimonial'" v-if="step === 6" />
    </div>
</template>

<script>
    import HeaderButtons from './components/headers-buttons/HeadersButtons';
    import Intro from './components/intro/Intro';
    import Buttons from './components/buttons/Buttons';
    import CentralFunctioning from './components/central-functioning/CentralFunctioning';

    import BombeoProcress  from './components/bombeo-procress/BombeoProcress';
    import TurbinacionProcress  from './components/turbinacion-procress/TurbinacionProcress';

    import VideoLoad from './components/video/Video'

    export default {
        name: 'App',
        components:  {
            HeaderButtons,
            Intro,
            Buttons,
            CentralFunctioning,
            BombeoProcress,
            TurbinacionProcress,
            VideoLoad
        },
        data() {
            return {
                requestedLanguage : null,
                language : 'es',
                step: 0,
                time: 20
            }
        },
        created() {
            this.checkUserActivity();
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('lang')) {
                const urlLang = urlParams.get('lang');

                if (urlLang === 'es' || urlLang === 'en') {
                    this.$i18n.locale = urlLang;
                    this.language = urlLang;
                    this.requestedLanguage = urlLang;
                }
            }
        },
        methods: {
            languageSelected: function (language) {
                this.$i18n.locale = language;
                this.language = language;
                this.step = 1;
            },
            buttonSelected: function (buttonSelected) {

                console.log(buttonSelected);

                switch (buttonSelected) {
                    case 'central-functioning':
                        this.step = 2;
                        break;
                    case 'bombeo-svg':
                    case 'bombeo':
                        this.step = 3;
                        break;
                    case 'turbinacion-svg':
                    case 'turbinacion':
                        this.step = 4;
                        break;
                    case 'description-project':
                        this.step = 5;
                        break;
                    case 'future-steps':
                        this.step = 6;
                        break;
                    default:
                        this.step = 1;
                }
            },
            stepBack: function () {
                if(this.step > 2) this.step = 1;
                else  this.step--
            },
            backHome: function () {
                console.log(process.env.VUE_APP_ONLINE_MODE)
                if (process.env.VUE_APP_ONLINE_MODE === "true") {
                    this.step = 0;
                } else {
                    window.location.href = "http://10.10.100.12/3_6";
                }
            },
            checkUserActivity() {
                setTimeout(() => {
                    if (this.time <= 0) {
                        if (process.env.VUE_APP_ONLINE_MODE === "true") {
                            this.step = 0;
                        } else {
                            window.location.href = "http://10.10.100.12/3_6";
                        }
                    } else {
                        this.time--;
                    }

                    console.log(this.time);
                    this.checkUserActivity();
                }, 1000);
            },
            userTouch() {

                this.time = 25;

                const Http = new XMLHttpRequest();
                const url='https://conectadosalfuturo.com/endpoints/save_toque.php';
                const params = 'interactiu=12';
                Http.open("POST", url);
                Http.send(params);

            }
        }
    }
</script>

<style lang="scss">

    @import "@/scss/_general.scss";

    .particles {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        #canvas {
            display: block;
            opacity: .25;
        }
    }

</style>
