<template>
    <div id="intro">

        <h1>{{ $t('preIntroTitle') }}</h1>
        <h2>{{ $t('preIntroSubtitle') }}</h2>

        <div class="intro-image">
            <img src="./assets/infografia-inicio-3.svg" alt="">
        </div>
        <div v-if="requestedLanguage">
            <div class="button" id="description-project" @click="languageSelected(requestedLanguage)">
                <span>{{ $t('start') }}</span>
            </div>
        </div>
        <div v-else>
            <div class="languages">
                <div class="language" @click="languageSelected('es')">
                    <img src="./assets/btn-ESP.svg" alt="">
                </div>
                <div class="language" @click="languageSelected('en')">
                    <img src="./assets/btn-ENG.svg" alt="">
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: "intro",
        props: ["requestedLanguage"],
        data() {
            return {
                interval : null
            }
        },
        created() {
            if (this.requestedLanguage === null) {
                this.interval = setInterval(() => {
                    this.$i18n.locale = (this.$i18n.locale === 'es') ? 'en' : 'es';
                }, 10000);
            }
        },
        methods: {
            languageSelected: function (language) {

                clearInterval( this.interval);
                this.$emit('language-selected', language)
            }
        }

    }
</script>

<style lang="scss" scoped>

    #intro {
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            margin-bottom: 20px;
        }
        h1,h2 {
            text-align: center;
        }
        .intro-image {
            width: 500px;
            height: auto;
            margin: 45px 0 55px 0;
            img {
                width: 100%;
                height: auto;
            }
        }
        .languages {
            display: flex;
            align-items: center;
            .language {
                width: 65px;
                height: auto;
                cursor: pointer;
                img {
                    width: 100%;
                    height: auto;
                }
                &:first-child {
                    margin-right: 25px;
                }
            }
        }
    }

    .button {
        cursor: pointer;
        width: 170px;
        height: 50px;
        border-radius: 25px;
        border: 2px solid var(--color-primary);
        background-color: var(--color-blue-new);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .image-icon {
            width: 30%;
            height: auto;
            margin-bottom: 10px;
            svg {
                width: 100%;
                height: auto;
                fill: #93a9b4;
                -webkit-transition: 0.3s;
                transition: 0.3s;
            }
        }
        span {
            display: block;
            width: 70%;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            font-size: 15px;
            line-height: 18px;
        }
        &:nth-child(2) {
            margin: 0 35px;
        }
        &.active {
            .image-icon {
                animation: pop 1.5s infinite;
                * {
                    fill: var(--color-primary) !important;
                }
            }
        }
    }

</style>
