<template>
    <div id="video">
        <div class="video-content">
            <video id="video-reproduction" autoplay :src="videoURL"> </video>
            <div class="line">
                <span v-bind:style="{ width: widthVideo + '%'}"></span>
            </div>
        </div>

        <div class="controls">
            <div class="control-button" @click="backVideo">
                <img src="./assets/btn-retroceso-v.svg" alt="">
            </div>
            <div class="control-button" @click="playPause">
                <img v-if="!play" src="./assets/btn-play-v.svg" alt="">
                <img v-else src="./assets/btn-pausa-v.svg" alt="">
            </div>
            <div class="control-button" @click="nextVideo">
                <img src="./assets/btn-avance-v.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "video",
        props: ["videoName"],
        data() {
            return {
                play : true,
                video: null,
                widthVideo: 0,
                duration: 0,
                language: 'es',
                videoURL: ''
            }
        },
        created() {
            this.language = this.$i18n.locale;

            if(this.videoName == 'chira') {
                this.videoURL = './' + this.language + '/chira.mp4';
            }

            if(this.videoName == 'testimonial') {
                this.videoURL = './' + this.language + '/testimonial.mp4';
            }

            console.log(this.videoURL);
        },
        mounted() {

            this.video = document.getElementById('video-reproduction');
            this.video.ontimeupdate = () => { this.timeVideo() };
            this.video.onloadedmetadata = () => { this.setDuration() };
        },
        methods: {
            playPause: function () {

                let video = document.getElementById('video-reproduction');

                if(this.play) video.pause();
                else video.play();

                this.play = !this.play;
            },
            backVideo: function () {
                let video = document.getElementById('video-reproduction');
                video.currentTime -= 10;
            },
            nextVideo: function () {
                let video = document.getElementById('video-reproduction');
                video.currentTime += 10;
            },
            timeVideo: function () {
                if(this.duration > 0) {
                    this.widthVideo = (this.video.currentTime/this.duration)*100;
                }
                this.$emit('video-reproduction')
            },
            setDuration: function () {
                this.duration = document.getElementById('video-reproduction').duration;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #video {
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        .video-content {
            position: relative;
            width: 100%;
            height: calc(100% - 110px);
            overflow: hidden;
            background-color: black;
            video {
                height: calc(100vh - 110px);
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            .line {
                z-index: 2;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 10px;
                width: 100%;
                background-color: #716c73;
                span {
                    display: block;
                    height: 10px;
                    background-color: #fff;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                }

            }
        }
        .controls {
            width: 100%;
            height: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--color-blue-dark);
            .control-button {
                width: 55px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
                &:nth-child(2) {
                    margin: 0 20px;
                }
            }
        }
    }
</style>
