<template>
    <div id="central-functioning">
        <h1>{{ $t('centralFunctioningTitle') }}</h1>
        <p>{{ $t('centralFunctioningSubtitle') }}</p>
        <div class="buttons">
            <div class="button" id="bombeo-svg" @click="buttonSelected('bombeo-svg')">
                <div class="image-icon">
                    <BtnBombeo />
                </div>
                <span>{{ $t('bombeoProcess') }}</span>
            </div>

            <div class="button" id="turbinacion-svg" @click="buttonSelected('turbinacion-svg')">
                <div class="image-icon">
                    <BtnTurbinacion />
                </div>
                <span>{{ $t('turbinacionProcess') }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import BtnBombeo from './assets/BtnBombeo';
    import BtnTurbinacion from './assets/BtnTurbinacion';

    export default {
        name: "central-functioning",
        components: {
            BtnBombeo,
            BtnTurbinacion
        },
        methods: {
            buttonSelected: function (button) {
                document.getElementById(button).classList.add('active');
                this.$emit('button-selected', button)
            }
        }
    }
</script>

<style lang="scss">
    #central-functioning {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            width: 900px;
            text-align: center;
            font-size: 22px;
            line-height: 30px;
            color: var(--color-text);
        }
        .buttons {
            margin-top: 70px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .button {
                cursor: pointer;
                width: 170px;
                height: 170px;
                border-radius: 25px;
                border: 2px solid var(--color-primary);
                background-color: var(--color-blue-new);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .image-icon {
                    width: 80%;
                    height: auto;
                    margin-bottom: 20px;
                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
                .image-icon {
                    #flecha-b, #flecha-t {
                        path {
                            animation: opacity 1.5s infinite;
                        }
                        path:nth-child(1) {
                            animation-delay: 0s
                        }
                        path:nth-child(2) {
                            animation-delay: 0.1s
                        }
                        path:nth-child(3) {
                            animation-delay: 0.2s
                        }
                        path:nth-child(4) {
                            animation-delay: 0.3s
                        }
                        path:nth-child(5) {
                            animation-delay: 0.4s
                        }
                        path:nth-child(6) {
                            animation-delay: 0.5s
                        }
                        path:nth-child(7) {
                            animation-delay: 0.6s
                        }
                        path:nth-child(8) {
                            animation-delay: 0.7s
                        }
                    }
                }
                span {
                    display: block;
                    width: 70%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 15px;
                    line-height: 18px;
                }
                &:nth-child(1) {
                    margin-right: 35px;
                }
                &.active {
                    border: 4px solid var(--color-primary);
                }
            }
            #pila-r1 {
                animation: opacity 2s infinite;
                animation-delay: 0s;
            }
            #pila-r2 {
                animation: opacity 2s infinite;
                animation-delay: 0.2s;
            }
            #pila-r3 {
                animation: opacity 2s infinite;
                animation-delay: 0.4s;
            }
            #pila-r4 {
                animation: opacity 2s infinite;
                animation-delay: 0.6s;
            }
            #pila-r5 {
                animation: opacity 2s infinite;
                animation-delay: 0.8s;
            }
            #pila-r6 {
                animation: opacity 2s infinite;
                animation-delay: 1s;
            }
            #pila-r7 {
                animation: opacity 2s infinite;
                animation-delay: 1.2s;
            }


            #pila-ray {
                animation: opacity 2s infinite;
            }
        }
    }

    @-webkit-keyframes opacity {
        from {opacity: 0;}
        to {opacity: 1;}
    }

</style>