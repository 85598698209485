<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.36 60.36">
        <defs>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_5" data-name="Capa 5">
                <path id="icon-descrip" class="icon-color" d="M42.25,25,27.16,16.24a6,6,0,0,0-9,5.22V38.9a6,6,0,0,0,9.05,5.22L42.25,35.4a6,6,0,0,0,0-10.44Zm-3,5.22L24.14,38.9V21.46ZM30.18,0A30.18,30.18,0,1,0,60.36,30.18,30.18,30.18,0,0,0,30.18,0Zm0,54.32A24.14,24.14,0,1,1,54.32,30.18,24.14,24.14,0,0,1,30.18,54.32Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "icon-description"
    }
</script>